(function () {
    'use strict';

    angular
        .module('vm')
        .factory('DomainSettings', DomainSettings);

    function DomainSettings ($http, CONFIG) {
        let domainSettingsPromise;

        return {
            clean: clean,
            get: get,
            reload: reload
        };

        //////////////

        function clean () {
            domainSettingsPromise = null;
        }

        function get () {
            if (!domainSettingsPromise) {
                domainSettingsPromise = $http.get('/api/v4.0/service/domain-settings.json')
                    .then(function (response) {
                        return angular.extend({}, CONFIG.defaultDomainSettings, response.data);
                    })
                    .catch(function () {
                        return CONFIG.defaultDomainSettings;
                    })
                ;
            }

            return domainSettingsPromise;
        }

        function reload () {
            clean();
            get();
        }
    }
}());