angular
    .module('vm')
    .component('signUpMay19Organic', {
        templateUrl: 'sign-up-may-19-organic.component.html',
        bindings: {

        },
        controllerAs: 'vm',
        controller: function (SignUpMay19Service, CONFIG, GoogleAnalyticsService) {
            const vm = this;

            vm.stepType = 'organic';
            vm.CONFIG = CONFIG;
            vm.brandName = CONFIG.projectName.match(/[A-Z][a-z]+/g).join(' ');
            vm.answer = null;

            vm.sendResponse = sendResponse;
            vm.$onInit = activate;

            ///////////

            function activate() {
                vm.answer = SignUpMay19Service.getStepAnswer(vm.stepType);
            }

            function sendResponse(answer) {
                GoogleAnalyticsService.fireEvent(
                    'SignUp May 19', 
                    'organic',
                    answer
                );

                SignUpMay19Service.saveStepAnswer(vm.stepType, answer);
                SignUpMay19Service.goToNextStep(vm.stepType);
            }
        }
    })
;