"use strict";

var LandingTracking = {
    data: {},

    initialize: function () {
        this.collectData();
        this.sendPixel();
        this.updateForms();
    },

    collectData: function() {
        // 1. REQUEST_URI = /welcome/adult?cid=test_cid&pid=somepid
        // 2. SERVER_NAME = some.name.com
        // 3. HTTP_REFERER = http://some.name.com/sdkksa/ccdd?something=ok

        this.data['referrer'] = encodeURIComponent(document.referrer);
        this.data['server_name'] = encodeURIComponent(document.location.hostname);

        var href = document.location.href;
        var len = document.location.protocol.length + 2 + document.location.hostname.length;
        var uri = href.substring(len);
        uri = uri.replace(/.*?(\/.*)/, '$1');

        this.data['request_uri'] = uri;
    },

    findFormItems: function () {
        var result = [];
        var items = document.getElementsByName('nextlove_user_short_registration_form[_token]');
        if (items.length > 0) {
            items.forEach(function(item){result.push(item);});
        }

        items = document.getElementsByName('nextlove_user_short_registration_form[_token]');
        if (items.length > 0) {
            items.forEach(function(item){result.push(item);});
        }

        items = document.getElementsByName('footer_nextlove_user_short_registration_form[_token]');
        if (items.length > 0) {
            items.forEach(function(item){result.push(item);});
        }

        return result;
    },

    onTrackingInitialized: function() {
        var event = document.createEvent('Event');
        event.state = 4;
        event.initEvent('digisec:on-tracking-executed', true, false);
        window.dispatchEvent(event);
    },

    updateForms: function() {
        var self = this;
        setTimeout(function() {

            var inputs = self.findFormItems();
            inputs.forEach(function(input) {
                var name = input.name.replace(/(.*)\[.*\]/, '$1');
                var http = new XMLHttpRequest();
                http.open("POST", '/forms/token/' + name, true);
                http.setRequestHeader("Content-type", "application/json;charset=UTF-8");
                http.onreadystatechange = function() {
                    if (http.readyState === 4) {
                        var response = JSON.parse(http.response);
                        input.value = response.token;
                    }
                };
                http.send();
            });

        }, 100);
    },

    sendPixel: function() {
        var self = this,
            http = new XMLHttpRequest();
        http.open("POST", '/api/v4.0/settings/track', true);
        http.setRequestHeader("Content-type", "application/json;charset=UTF-8");
        http.onreadystatechange = function() {
            if (http.readyState === 4) {
                var response = JSON.parse(http.response);
                self.changeLocale(response.locale);
                self.onTrackingInitialized();
            }
        };
        http.send(JSON.stringify(this.data));
    },

    changeLocale: function (locale) {
        var newUrl,
            curUrl = window.location.pathname,
            match  = curUrl.match(/^\/([a-z]{2}|[a-z]{2}_[A-Z]{2}|zh_Hans_[A-Z]{2})\//);

        if (!curUrl.match(/^\/(([a-z]{2}|[a-z]{2}_[A-Z]{2}|zh_Hans_[A-Z]{2})\/)?(land|blog).*/)) {
            return;
        }

        if (match) {
            if (match[1] === locale) {
                return false;
            }
            newUrl = curUrl.replace(/\/([a-z]{2}|[a-z]{2}_[A-Z]{2}|zh_Hans_[A-Z]{2})\//g, '/' + locale + '/');
        } else {
            newUrl = curUrl.replace(/^(https?.\/\/(\w+\.)+\w+)\/(.*)$/, '$1/' + locale + '/$3');
        }

        window.location.href = newUrl;
    }
};

LandingTracking.initialize();

