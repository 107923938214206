(function () {
    'use strict';

    angular
        .module('vm.common')
        .component('appVmcSlider', {
            templateUrl: 'vmc-slider.component.html',
            bindings: {},
            controllerAs: 'vm',
            controller: function ($timeout, ResponsiveService) {
                const vm = this;

                vm.enabled = false;
                vm.active = false;
                vm.country = null;

                vm.adbutlerId = 175289;
                vm.width = 450;
                vm.height = 195;
                vm.divId = null;

                const zones = {
                    // 'mobile_retina': 352882,
                    // 'mobile_non_retina': 352890,
                    'desktop_retina': 352893,
                    'desktop_non_retina': 352892,
                };

                activate();

                ////////////////

                function activate() {
                    const zoneId = getZoneId();

                    if (zoneId) {
                        injectBanner(zoneId);

                        window.addEventListener('adbutlerOnResponse', (data) => {
                            if (data.detail.filled) {
                                vm.enabled = true;

                                $timeout(() => {
                                    vm.active = true;
                                }, 3000);
                            }
                        });
                    }
                }

                function getZoneId() {
                    let key = ResponsiveService.isMobile() ? 'mobile' : 'desktop';
                    key += '_';
                    key += ResponsiveService.isRetina() ? 'retina' : 'non_retina';

                    return zones[key];
                }

                function injectBanner(zoneId) {
                    if (!window.AdButler) {(
                        function () {
                            var s = document.createElement("script"); 
                            s.async = true; 
                            s.type = "text/javascript";
                            s.src = 'https://servedbyadbutler.com/app.js';
                            var n = document.getElementsByTagName("script")[0]; 
                            n.parentNode.insertBefore(s, n);
                        }());
                    }
                      
                    window.AdButler = window.AdButler || {};
                    window.abkw = window.abkw || '';
                    window['plc' + zoneId] = window['plc' + zoneId] || 0;
                  
                    window.AdButler.ads = AdButler.ads || [];
                    let plc = window['plc' + zoneId];
                    
                    vm.divId = 'placement_' + zoneId + '_' + plc;
                  
                    window.AdButler.ads.push({
                        handler: function (opt) {
                            window.AdButler.register(
                                vm.adbutlerId,
                                zoneId,
                                [vm.width, vm.height],
                                'placement_' + zoneId + '_' + opt.place, 
                                opt
                            );
                        },
                        opt: { 
                            place: window['plc' + zoneId]++,
                            keywords: window.abkw, 
                            domain: 'servedbyadbutler.com', 
                            click:'CLICK_MACRO_PLACEHOLDER'
                        }
                    });
                }
            }
        });
}());