(function () {
    'use strict';

    angular
        .module('vm.common')
        .factory('Notifications', Notifications);

    function Notifications () {
        var onNotificationAdd;

        return {
            add: add,
            setOnNotificationAdd: setOnNotificationAdd
        };

        //////////////

        function add(notificationData) {
            if (onNotificationAdd) {
                onNotificationAdd(notificationData);
            }
        }

        function setOnNotificationAdd(fn) {
            onNotificationAdd = fn;
        }
    }
}());