angular
    .module('vm')
    .component('signUpMay19UserDetails', {
        templateUrl: 'sign-up-may-19-user-details.component.html',
        bindings: {
            step: '=',
        },
        controllerAs: 'vm',
        controller: function (ProfileEnumsService, SignUpMay19Service, CONFIG, $scope, $timeout, 
            GoogleAnalyticsService, $element) {
            const vm = this;

            const defaultValues = {
                'female': {
                    'height': 150,
                    'weight': 50
                },
                'male': {
                    'height': 170,
                    'weight': 70
                }
            };

            vm.enums = {};
            vm.fieldConfig;
            vm.answer;

            vm.addAnswer = addAnswer;
            vm.findFieldConfig = findFieldConfig;

            vm.$onInit = activate;

            ///////////

            function activate() {
                onStepUpdate();

                $scope.$watch('vm.step', () => {
                    onStepUpdate();
                });
            }

            function addAnswer(option) {
                vm.answer = option.id;

                GoogleAnalyticsService.fireEvent(
                    'SignUp May 19', 
                    vm.step.type,
                    option.id
                );

                SignUpMay19Service.saveStepAnswer(vm.step.type, option.id);

                $timeout(() => SignUpMay19Service.goToNextStep(vm.step.type), 0);
            }

            function updateFieldConfig() {
                vm.fieldConfig = findFieldConfig(vm.step.type);
            }

            function findFieldConfig (fieldName) {
                let fieldConfig;
            
                CONFIG.profileSections.forEach((section) => {
                  let fields = section.fields;
            
                  fields.forEach((field) => {
                    if (field.name == fieldName) {
                      fieldConfig = field;
                    }
                  });
                });
            
                return fieldConfig;
            }

            function onStepUpdate() {
                updateFieldConfig();
                vm.answer = SignUpMay19Service.getStepAnswer(vm.step.type);

                ProfileEnumsService.getAllEnums()
                    .then((enums) => {
                        vm.enums = enums;

                        if (vm.step.type === 'height' || vm.step.type === 'weight') {
                            scrollToOption(vm.answer);
                        }
                    })
                ;
            }

            function scrollToOption(answer) {
                $timeout(() => {
                    let value;

                    if (answer) {
                        value = answer;
                    } else {
                        const genderAnswer = SignUpMay19Service.getStepAnswer('gender');
                    
                        if (genderAnswer) {
                            const defaultValue = defaultValues[genderAnswer.sex][vm.step.type];
    
                            if (defaultValue) {
                                value = defaultValue;
                            }
                        }
                    }
                    
                    if (value) {
                        const el = $element[0].querySelector('#answer-' + value);
                        const containerEl = $element[0].querySelector('.step-content');

                        if (el) {
                            containerEl.scroll({
                                top: el.offsetTop - 300, 
                                left: 0, 
                                behavior: 'smooth'
                            });
                        }
                    }
                }, 100);
            }
        }
    })
;