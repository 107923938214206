'use strict';

angular
    .module('vm')
    .factory('RelationshipStatusSelect', RelationshipStatusSelect);

function RelationshipStatusSelect($translate) {
    var MARRIED_MAN_SEEKING_WOMAN   = 0,
        MARRIED_WOMAN_SEEKING_MAN   = 1,
        ATTACHED_MAN_SEEKING_WOMAN  = 2,
        ATTACHED_WOMAN_SEEKING_MAN  = 3,
        SINGLE_MAN_SEEKING_WOMAN    = 4,
        SINGLE_WOMAN_SEEKING_MAN    = 5,
        MAN_SEEKING_MAN             = 6,
        WOMAN_SEEKING_WOMAN         = 7,
        DIVORCED_MAN_SEEKING_WOMAN  = 8,
        DIVORCED_WOMAN_SEEKING_MAN  = 9;

    var SEX_MALE    = 'male',
        SEX_FEMALE  = 'female';

    var SINGLE      = 'single',
        SEPARATED   = 'separated',
        MARRIED     = 'married',
        DIVORCED    = 'divorced',
        WIDOWED     = 'widowed',
        ATTACHED    = 'attached';


    return {
        getUserDefinitionVM: getUserDefinitionVM,
        getUserDefinitionMap: getUserDefinitionMap
    };

    //////////////

    function getUserDefinitionVM() {
        return [
            {
                id: MARRIED_MAN_SEEKING_WOMAN,
                text: $translate.instant('married_man_seeking_a_woman'),
                sex: 'man'
            },
            {
                id: MARRIED_WOMAN_SEEKING_MAN,
                text: $translate.instant('married_woman_seeking_a_man'),
                sex: 'woman'
            },
            {
                id: ATTACHED_MAN_SEEKING_WOMAN,
                text: $translate.instant('attached_man_seeking_a_woman'),
                sex: 'man'
            },
            {
                id: ATTACHED_WOMAN_SEEKING_MAN,
                text: $translate.instant('attached_woman_seeking_a_man'),
                sex: 'woman'
            },
            {
                id: SINGLE_MAN_SEEKING_WOMAN,
                text: $translate.instant('single_man_seeking_a_woman'),
                sex: 'man'
            },
            {
                id: SINGLE_WOMAN_SEEKING_MAN,
                text: $translate.instant('single_woman_seeking_a_man'),
                sex: 'woman'
            },
            {
                id: MAN_SEEKING_MAN,
                text: $translate.instant('man_seeking_a_man'),
                sex: 'man'
            },
            {
                id: WOMAN_SEEKING_WOMAN,
                text: $translate.instant('woman_seeking_a_woman'),
                sex: 'woman'
            }
        ]
    }

    function getUserDefinitionNL() {
        return [];
    }

    function getUserDefinitionMap() {
        return [
            MARRIED_MAN_SEEKING_WOMAN = {
                'sex': SEX_MALE,
                'seekingSex': SEX_FEMALE,
                'relationshipStatus': MARRIED
            },
            MARRIED_WOMAN_SEEKING_MAN = {
                'sex': SEX_FEMALE,
                'seekingSex': SEX_MALE,
                'relationshipStatus': MARRIED
            },
            ATTACHED_MAN_SEEKING_WOMAN = {
                'sex': SEX_MALE,
                'seekingSex': SEX_FEMALE,
                'relationshipStatus': ATTACHED
            },
            ATTACHED_WOMAN_SEEKING_MAN = {
                'sex': SEX_FEMALE,
                'seekingSex': SEX_MALE,
                'relationshipStatus': ATTACHED
            },
            SINGLE_MAN_SEEKING_WOMAN = {
                'sex': SEX_MALE,
                'seekingSex': SEX_FEMALE,
                'relationshipStatus': SINGLE
            },
            SINGLE_WOMAN_SEEKING_MAN = {
                'sex': SEX_FEMALE,
                'seekingSex': SEX_MALE,
                'relationshipStatus': SINGLE
            },
            MAN_SEEKING_MAN = {
                'sex': SEX_MALE,
                'seekingSex': SEX_MALE,
                'relationshipStatus': SINGLE
            },
            WOMAN_SEEKING_WOMAN = {
                'sex': SEX_FEMALE,
                'seekingSex': SEX_FEMALE,
                'relationshipStatus': SINGLE
            },
            DIVORCED_MAN_SEEKING_WOMAN = {
                'sex': SEX_MALE,
                'seekingSex': SEX_FEMALE,
                'relationshipStatus': DIVORCED
            },
            DIVORCED_WOMAN_SEEKING_MAN = {
                'sex': SEX_FEMALE,
                'seekingSex': SEX_MALE,
                'relationshipStatus': DIVORCED
            },
        ];
    }
}