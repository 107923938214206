(function () {
    'use strict';

    angular
        .module('vm')
        .factory('LangSelectorModal', LangSelectorModal);

    function LangSelectorModal ($modal, $rootScope, $http) {
        var modalScope = $rootScope.$new(true);
        var vm = modalScope.vm = {};

        vm.domains = null;
        vm.changeLanguage = changeLanguage;

        return {
            show: show
        };

        //////////////

        function show() {
            getDomains();

            $modal({
                templateUrl: 'lang-selector-modal.html',
                show: true,
                scope: modalScope
            });
        }

        function getDomains() {
            $http.get('/api/v4.0/service/domain-links.json?type=mweb')
                .then(function (response) {
                    vm.domains = response.data;
                })
            ;
        }

        function changeLanguage(locale) {
            var newUrl,
                curUrl = window.location.href;

            if (/\/([a-z]{2}|[a-z]{2}_[A-Z]{2}|zh_Hans_[A-Z]{2})\//.test(curUrl)) {
                newUrl = curUrl.replace(/\/([a-z]{2}|[a-z]{2}_[A-Z]{2}|zh_Hans_[A-Z]{2})\//g, '/' + locale + '/');
            } else {
                newUrl = curUrl.replace(/^(https?.\/\/(\w+\.)+\w+)\/(.*)$/, '$1/' + locale + '/$3');
            }

            window.location.href = newUrl;
        }
    }
}());