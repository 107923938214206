(function () {
    'use strict';

    angular
        .module('vm')
        .factory('Auth', Auth);

    function Auth($api, BrowserOauth, $cookies) {
        return {
            oAuthLogin,
            redirectToFrontend
        };

        //////////////

        function oAuthLogin (provider) {
            return BrowserOauth.auth(provider)
                .then(function(token) {
                    var url = 'oauth-hybrid-auth.json'
                        + '?service=' + provider
                        + '&token=' + token.access_token
                        + '&expirationTime=' + token.expires_in;

                    return $api.get(url)
                        .then(function (response) {
                            return response.data;
                        })
                        ;
                })
            ;
        }

        function redirectToFrontend(token) {
            var cookieOptions = {
                path: '/',
                expires: new Date(new Date().setFullYear(new Date().getFullYear() + 5)),
            };

            try {
                $cookies.remove('preferV4', cookieOptions);
                $cookies.remove('preferMobile', cookieOptions);
            }
            catch (e) {}

            // if (getScreenWidth() < 1000) {
            //     $cookies.put('preferMobile', 'true', cookieOptions);
            //
            //     window.location.href = '/?nc#/auto-login?token=' + token;
            // }
            // else {
                $cookies.put('preferV4', 'true', cookieOptions);

                window.location.href = '/?nc#/auto-login;token=' + token;
            // }
        }

        function getScreenWidth() {
            try {
                return window.innerWidth
                    || document.documentElement.clientWidth
                    || document.body.clientWidth;
            } catch (err) {
                console.error(err);
                return 0;
            }
        }
    }
}());