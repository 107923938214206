(function () {
    'use strict';

    angular
        .module('vm')
        .factory('BrowserOauth', BrowserOauth);

    function BrowserOauth($q, DOMAIN_SETTINGS, CONFIG) {
        return {
            auth,
            getAuthUrl
        };

        //////////////

        function auth(provider) {
            return new $q((resolve, reject) => {
                getAuthUrl(provider)
                    .then((authUrl) => {
                        let authWindow = window.open(authUrl);

                        let interval = setInterval(() => {
                            try {
                                let url = authWindow.location.href;

                                if (url && url.includes('/oauth.html')) {
                                    let callbackResponse = (authWindow.location.hash).split('#')[1];
                                    let responseParameters = (callbackResponse).split('&');
                                    let parameterMap = {};

                                    for (let i = 0; i < responseParameters.length; i++) {
                                        parameterMap[responseParameters[i].split('=')[0]] = responseParameters[i].split('=')[1];
                                    }

                                    clearInterval(interval);
                                    authWindow.close();

                                    if (parameterMap.access_token) {
                                        let token = {
                                            access_token: parameterMap.access_token,
                                            expires_in: parseInt(parameterMap.expires_in),
                                            created_at: new Date()
                                        };

                                        resolve(token);
                                    }
                                    else {
                                        reject('problem authenticating');
                                    }
                                }
                            }
                            catch (e) {
                            }
                        }, 1000);
                    });
            });
        }

        function getAuthUrl(provider) {
            let redirectLocation = [window.location.protocol, '//', window.location.host, '/oauth.html'].join('');

            return new $q((resolve) => {
                let providers = {
                    facebook: {
                        authenticate: 'https://www.facebook.com/v2.0/dialog/oauth?client_id='
                        + DOMAIN_SETTINGS.facebookClientId
                        + '&redirect_uri=' + redirectLocation + '&response_type=token&scope='
                        + CONFIG.oAuthProviders.facebook.appScope.join(',')
                    },
                    instagram: {
                        authenticate: 'https://api.instagram.com/oauth/authorize/?client_id='
                        + DOMAIN_SETTINGS.instagramClientId
                        + '&redirect_uri=' + redirectLocation + '&response_type=token&scope='
                        + CONFIG.oAuthProviders.instagram.appScope.join('+')
                    },
                    windows_live: {
                        authenticate: 'https://login.live.com/oauth20_authorize.srf?client_id='
                        + DOMAIN_SETTINGS.windowsLiveClientId
                        + "&scope=" + CONFIG.oAuthProviders.windowsLive.appScope.join('+')
                        + '&response_type=token&display=touch&redirect_uri=' + redirectLocation
                    },
                    google: {
                        authenticate: 'https://accounts.google.com/o/oauth2/auth?client_id='
                        + DOMAIN_SETTINGS.googleClientId
                        + '&redirect_uri=' + redirectLocation
                        + '&scope=' + CONFIG.oAuthProviders.google.appScope.join(' ')
                        + '&approval_prompt=force&response_type=token'
                    }
                };

                resolve(providers[provider].authenticate);
            });
        }
    }
}());