(function () {
    'use strict';

    angular
        .module('vm')
        .factory('UrlSearchParamsService', UrlSearchParamsService)
    ;

    function UrlSearchParamsService() {
        const urlSearchParams = new URLSearchParams(window.location.search);

        return {
            get: get,
            has: has,
        };

        //////////////

        function get(param) {
            return urlSearchParams.get(param);
        }

        function has(param) {
            return urlSearchParams.has(param);
        }
    }
}());