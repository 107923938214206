(function () {
    'use strict';

    angular
        .module('vm')
        .factory('ProfileEnumsService', ProfileEnumsService)
    ;

    function ProfileEnumsService($api, CONFIG, translateEnumFilter, $q) {
        let allEnumsPromise;

        return {
            getAllEnums: getAllEnums,
            getEnums: getEnums,
            getWeights: getWeights,
            getHeights: getHeights,
        };

        //////////////

        function getAllEnums() {
            if (!allEnumsPromise) {
                allEnumsPromise = $q((resolve) => {
                    const allEnums = {};
    
                    allEnums.height = this.getHeights();
                    allEnums.weight = this.getWeights();
    
                    getEnums()
                        .then((enums) => {
                            Object.keys(enums).forEach((key) => {
                                allEnums[key] = enums[key];
                            });
    
                            resolve(allEnums);
                        })
                    ;
                });
            }

            return allEnumsPromise;
        }

        function getEnums(withPreferNotToSay = true) {
            return $api.get(`enums/profile?project=${CONFIG.project}`)
              .then((response) => response.data)
              .then((enums) => {
                let convertedEnums = {};
        
                Object.keys(enums).forEach((key) => {
                  convertedEnums[key] = enums[key].map((option) => {
                    return {
                      id: option,
                      text: translateEnumFilter(option, key)
                    }
                  });
        
                  if (!withPreferNotToSay) {
                    convertedEnums[key] = convertedEnums[key].filter(item => item.id !== 'preferNotToSay');
                  }
                });
        
                convertedEnums['status'] = convertedEnums['relationship_status'];
        
                return convertedEnums;
              })
            ;
          }

        function getWeights() {
            let weights = [];
            let i;
        
            for (i = 30; i <= 200; i++) {
                weights.push({
                    id: '' + i,
                    text: i + ' kg'
                });
            }
        
            return weights;
        }
        
        function getHeights() {
            let heights = [];
            let i;
        
            for (i = 120; i <= 220; i++) {
                heights.push({
                    id: '' + i,
                    text: i + ' cm'
                });
            }
        
            return heights;
          }
    }
}());