(function () {
    'use strict';

    angular
        .module('vm.common')
        .factory('ResponsiveService', ResponsiveService);

    function ResponsiveService() {
        return {
            getScreenWidth: getScreenWidth,
            isMobile: isMobile,
            isRetina: isRetina,
        };

        //////////////

        function isMobile() {
            return getScreenWidth() < 500;
        }

        function isRetina() {
            return window.devicePixelRatio > 1;
        }

        function getScreenWidth() {
            try {
                return window.innerWidth
                    || document.documentElement.clientWidth
                    || document.body.clientWidth;
            } catch (err) {
                console.error(err);
                return 0;
            }
        }
    }
}());