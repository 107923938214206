angular
    .module('vm')
    .component('signUpMay19AcceptTerms', {
        templateUrl: 'sign-up-may-19-accept-terms.component.html',
        bindings: {

        },
        controllerAs: 'vm',
        controller: function (SignUpMay19Service, SignupFetchErrorMessage, Notifications, Auth, GoogleAnalyticsService) {
            const vm = this;

            vm.formData = {
                agreeWithTerms: false,
            };

            vm.submitForm = submitForm;
            vm.$onInit = activate;
            vm.inProcess = false;
            vm.stepType = 'acceptTerms';

            ////////////

            function activate() {
                vm.formData = SignUpMay19Service.getStepAnswer(vm.stepType);
            }

            function submitForm() {
                SignUpMay19Service.saveStepAnswer(vm.stepType, vm.formData);

                if (!vm.inProcess) {
                    vm.inProcess = true;

                    SignUpMay19Service.sendSignUpForm()
                        .then((response) => {
                            GoogleAnalyticsService.fireEvent(
                                'SignUp May 19', 
                                'accept-terms'
                            );

                            SignUpMay19Service.removeAnswers();
                            SignUpMay19Service.removePageLeaveListener();
                            Auth.redirectToFrontend(response.data.token);
                        })
                        .catch((response) => {
                            var message = SignupFetchErrorMessage(response.data);

                            if (message) {
                                Notifications.add({
                                    html: message
                                });
                            }
                        })
                        .finally(() => {
                            vm.inProcess = false;
                        })
                    ;
                }
            }
        }
    })
;