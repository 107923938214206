(function () {
    'use strict';

    angular
        .module('vm.common')
        .component('appNotifications', {
            templateUrl: 'notifications.component.html',
            bindings: {

            },
            controllerAs: 'vm',
            controller: function (Notifications, $timeout) {
                var vm = this;

                vm.notifications = [];
                vm.$onInit = $onInit;
                vm.removeNotification = removeNotification;

                //////////

                function $onInit() {
                    Notifications.setOnNotificationAdd(onNotificationAdd.bind(this));
                }

                function onNotificationAdd(notification) {
                    vm.notifications.push(notification);

                    $timeout(function () {
                        removeNotification(notification);
                    }, 5000);
                }

                function removeNotification(notification) {
                    vm.notifications.splice(vm.notifications.indexOf(notification), 1);
                }
            }
        });
}());