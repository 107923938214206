(function () {
    'use strict';

    angular
        .module('vm.common')
        .component('crossSellingModal', {
            templateUrl: 'cross-selling-modal.component.html',
            bindings: {
            },
            controllerAs: 'vm',
            controller: function (CONFIG, $api, Auth, CursorWait, $translate) {
                const vm = this;

                vm.showModal = false;
                vm.project = CONFIG.project;
                vm.projectName = CONFIG.projectName;
                vm.username = null;
                vm.donorProjectName = null;
                vm.formSubmitted = false;

                vm.translationKeys = {
                    vm: {
                        text1: 'vm_layout.dating.top_title',
                        text2: 'gold_lander.member_count'
                    },
                    rmb: {
                        text1: 'rmb.relationships_on_your_terms',
                        text2: 'rmb.find_a_sugarbaby_or_sugardady_in_your_area_today'
                    },
                    nl: {
                        text1: 'nextlove.social_network',
                        text2: 'vm_layout.index.top_bullet_2'
                    }
                };

                vm.activateProfile = activateProfile;

                activate();

                /////////////

                function activate () {
                    const crossHash = getParameterByName('cross_hash');

                    if (crossHash) {
                        $api.get('cross-selling/check/' + crossHash)
                            .then(function (response) {
                                if (response.data.project) {
                                    vm.donorProjectName = response.data.project;
                                    vm.username = response.data.username;
                                    vm.showModal = true;
                                }
                            })
                        ;
                    }
                }

                function activateProfile () {
                    const crossHash = getParameterByName('cross_hash');

                    if (vm.formSubmitted) {
                        return;
                    }

                    vm.formSubmitted = true;

                    CursorWait.enable();

                    $api.post('cross-selling/signup/' + crossHash)
                        .then(function (response) {
                            Auth.redirectToFrontend(response.data.token);
                        })
                        .catch(function () {
                            CursorWait.disable();
                            alert($translate.instant('error.something_went_wrong'));
                            vm.formSubmitted = false;
                        })
                    ;
                }

                function getParameterByName(name) {
                    const url = window.location.href;
                    name = name.replace(/[\[\]]/g, '\\$&');
                    const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
                        results = regex.exec(url);
                    if (!results) return null;
                    if (!results[2]) return '';
                    return decodeURIComponent(results[2].replace(/\+/g, ' '));
                }
            }
        });
}());