angular
    .module('vm')
    .component('signUpMay19Security', {
        templateUrl: 'sign-up-may-19-security.component.html',
        bindings: {

        },
        controllerAs: 'vm',
        controller: function () {
            const vm = this;
        }
    })
;