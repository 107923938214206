angular
    .module('vm')
    .component('signUpMay19Password', {
        templateUrl: 'sign-up-may-19-password.component.html',
        bindings: {

        },
        controllerAs: 'vm',
        controller: function (SignUpMay19Service, GoogleAnalyticsService) {
            const vm = this;

            vm.valid = false;
            vm.formData = {
                password: '',
            };

            vm.passwordRules = {
                latter: {
                    valid: false,
                    rule: /([a-zA-Z])/,
                },
                number: {
                    valid: false,
                    rule: /([0-9])/,
                },
                length: {
                    valid: false,
                    rule: /(.{6,})/,
                }
            };

            vm.passwordVisible = false;
            vm.stepType = 'password';

            vm.checkRules = checkRules;
            vm.submitForm = submitForm;
            vm.$onInit = activate;

            ////////////

            function activate() {
                const answer = SignUpMay19Service.getStepAnswer(vm.stepType);

                if (answer) {
                    vm.formData = answer;
                    checkRules();
                }
            }

            function submitForm() {
                GoogleAnalyticsService.fireEvent(
                    'SignUp May 19', 
                    'password'
                );

                SignUpMay19Service.saveStepAnswer(vm.stepType, vm.formData);
                SignUpMay19Service.goToNextStep(vm.stepType);
            }

            function checkRules() {
                vm.valid = true;

                Object.keys(vm.passwordRules).forEach((key) => {
                    if (vm.passwordRules[key].rule.test(vm.formData.password)) {
                        vm.passwordRules[key].valid = true;
                    } else {
                        vm.passwordRules[key].valid = false;
                        vm.valid = false;
                    }
                });
            }
        }
    })
;