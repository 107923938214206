angular.module('vm', [
    'vcRecaptcha',
    'pascalprecht.translate',
    'mgcrea.ngStrap',
    'ngAnimate',
    'ngCookies',
    'ngSanitize',
    'ngPasswordMeter',
    'ui.bootstrap',
    'vm.common',
    'vm.config',
    'ui.select',
    'LocalStorageModule'
])

    .config(function ($translateProvider) {
        $translateProvider.fallbackLanguage('en');
    })

    .filter('passwordToAsterisk', function () {
        return function (input) {
            var output = '';

            for(var i = 0; i <= input.length; i++) {
                output += '*';
            }

            return output;
        };
    })

    .run(function ($injector) {
        window.injector = $injector;
    })

;
