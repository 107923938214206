'use strict';

angular
    .module('vm')
    .factory('DateSelect', DateSelect);

function DateSelect($translate) {
    return {
        getDays: getDays,
        getMonths: getMonths,
        getYears: getYears
    };

    //////////////

    function getDays(maxDate) {
        var days = [];

        if (!maxDate) {
            var maxDate = 31;
        }

        for(var day = 1; day <= maxDate; day++) {
            var fullDay = day.toString();

            if (day < 10) {
                fullDay = '0' + fullDay;
            }

            days.push({
                id: fullDay,
                text: fullDay
            });
        }

        return days;
    }

    function getMonths() {
        return [
            {
                id: '01',
                text: $translate.instant('common.date.january')
            },
            {
                id: '02',
                text: $translate.instant('common.date.february')
            },
            {
                id: '03',
                text: $translate.instant('common.date.march')
            },
            {
                id: '04',
                text: $translate.instant('common.date.april')
            },
            {
                id: '05',
                text: $translate.instant('common.date.may')
            },
            {
                id: '06',
                text: $translate.instant('common.date.june')
            },
            {
                id: '07',
                text: $translate.instant('common.date.july')
            },
            {
                id: '08',
                text: $translate.instant('common.date.august')
            },
            {
                id: '09',
                text: $translate.instant('common.date.september')
            },
            {
                id: '10',
                text: $translate.instant('common.date.october')
            },
            {
                id: '11',
                text: $translate.instant('common.date.november')
            },
            {
                id: '12',
                text: $translate.instant('common.date.december')
            }
        ];
    }

    function getYears() {
        var years = [];
        var now = new Date();
        var minYear = now.getFullYear() - 100;
        var maxYear = now.getFullYear() - 18;

        for(var year = maxYear; year >= minYear; year--) {
            years.push({
                id: '' + year,
                text: '' + year
            });
        }

        return years;
    }
}