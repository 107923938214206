(function () {
    'use strict';

    angular
        .module('vm')
        .factory('SignupFetchErrorMessage', SignupFetchErrorMessage);

    function SignupFetchErrorMessage ($translate) {
        return function (response) {
            var message = '';

            if (angular.isDefined(response.errors)) {
                if (response.errors === null) {
                    message = response.message;
                } else if (angular.isString(response.errors)) {
                    message = response.errors;
                }
                else {
                    angular.forEach(response.errors.children, function (value, key) {
                        if (angular.isDefined(value.errors)) {
                            message += {
                                "name": $translate.instant('common.username_label') + ": ",
                                "email": $translate.instant('common.email') + ": ",
                                "password": $translate.instant('common.password_label') + ": ",
                                "sex": "Sex: ",
                                "birthDate": $translate.instant('common.birthday_label') + ": "
                            }[key];

                            message += value.errors.join(' ') + '<br/>';
                        }
                    });

                    if (angular.isDefined(response.errors.errors)) {
                        message += response.errors.errors.join('');
                    }
                }
            }

            return message;
        };
    }
}());