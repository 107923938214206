angular
    .module('vm')
    .component('appLangSelector', {
        templateUrl: 'lang-selector.component.html',
        bindings: {

        },
        controllerAs: 'vm',
        controller: function (LangSelectorModal) {
            var vm = this;

            vm.showLangSelector = showLangSelector;

            /////////////

            function showLangSelector() {
                LangSelectorModal.show();
            }
        }
    });