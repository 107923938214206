(function () {

    angular
        .module('vm.common')
        .service('$api', $api);

    function $api ($http) {

        return {
            get: get,
            delete: _delete,
            patch: patch,
            post: post,
            put: put,
            getFullUrl: getFullUrl
        };

        /////////////////

        function get (path, options) {
            return $http.get(getFullUrl(path), options);
        }

        function post (path, parameters, options) {
            return $http.post(getFullUrl(path), parameters, options);
        }

        function _delete (path) {
            return $http.delete(getFullUrl(path));
        }

        function patch (path, parameters, options) {
            return $http.patch(getFullUrl(path), parameters, options);
        }

        function put (path, parameters, options) {
            return $http.put(getFullUrl(path), parameters, options);
        }

        function getFullUrl (path) {
            return '/api/v4.0/' + path;
        }
    }
}());