angular
    .module('vm')
    .component('appCookieAgreement', {
        templateUrl: 'cookie-agreement.component.html',
        bindings: {
        },
        controllerAs: 'vm',
        controller: function ($cookies) {
            var vm = this;

            vm.step = null;
            vm.$onInit = $onInit;
            vm.onReadMore = onReadMore;
            vm.onNotNow = onNotNow;
            vm.onIAgree = onIAgree;

            function $onInit() {
                var agreeWithCookie = $cookies.get('agreed_with_cookie_policy');

                if (!agreeWithCookie) {
                    vm.step = 'show';
                }
            }

            function onReadMore() {
                vm.step = 'show';
            }

            function onNotNow() {
                vm.step = 'notnow';
            }

            function onIAgree() {
                var now  = new Date(),
                    exp  = new Date(now.getFullYear() + 10, now.getMonth(), now.getDate());
                var unix = Math.round(+new Date()/1000);

                $cookies.put('agreed_with_cookie_policy', unix, {
                    expires: exp
                });

                vm.step = null;
            }
        }
    });