(function () {
    'use strict';

    angular
        .module('vm.common')
        .component('appBanner', {
            templateUrl: 'app-banner.component.html',
            bindings: {
                type: '=',
            },
            controllerAs: 'vm',
            controller: function (CONFIG) {
                const vm = this;

                vm.CONFIG = CONFIG;
                vm.showBanner = true;

                vm.close = close;

                //////////

                function close() {
                    vm.showBanner = false;

                    const els = document.querySelectorAll('.has-app-banner');

                    if (els) {
                        for (let el of els) {
                            el.classList.remove('has-app-banner');
                        }
                    }
                }
            }
        });
}());