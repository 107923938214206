(function () {
    'use strict';

    angular
        .module('vm.common')
        .component('appNotification', {
            templateUrl: 'notification.component.html',
            bindings: {
                notification: '=',
                close: '&'
            },
            controllerAs: 'vm',
            controller: function () {
                var vm = this;

                vm.onClose = onClose;

                //////////

                function onClose() {
                    vm.close(vm.notification);
                }
            }
        });
}());