angular
    .module('vm')
    .component('signUpMay19Gender', {
        templateUrl: 'sign-up-may-19-gender.component.html',
        bindings: {

        },
        controllerAs: 'vm',
        controller: function (SignUpMay19Service, GoogleAnalyticsService) {
            const vm = this;

            vm.formData = {};

            vm.options = {
                'sex': [
                    {
                        id: 'male',
                        label: 'enumusersex_male',
                    },
                    {
                        id: 'female',
                        label: 'enumusersex_female',
                    },
                ],
                'seekingSex': [
                    {
                        id: 'male',
                        label: 'interested_in.male',
                    },
                    {
                        id: 'female',
                        label: 'interested_in.female',
                    },
                    {
                        id: 'any',
                        label: 'interested_in.any',
                    },
                ]
            };

            vm.submitForm = submitForm;
            vm.$onInit = activate;
            vm.stepType = 'gender';

            ///////////////

            function activate() {
                vm.formData = SignUpMay19Service.getStepAnswer(vm.stepType);

                if (!vm.formData) {
                    vm.formData = {
                        sex: 'male',
                        seekingSex: 'female',
                    };
                }
            }

            function submitForm() {
                GoogleAnalyticsService.fireEvent(
                    'SignUp May 19', 
                    'sex',
                    vm.formData.sex
                );

                GoogleAnalyticsService.fireEvent(
                    'SignUp May 19', 
                    'seekingSex',
                    vm.formData.seekingSex
                );

                SignUpMay19Service.saveStepAnswer(vm.stepType, vm.formData);
                SignUpMay19Service.goToNextStep(vm.stepType);
            }
        }
    })
;