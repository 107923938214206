(function () {
    'use strict';

    angular
        .module('vm')
        .filter('translateEnum', translateEnum)
    ;

    function translateEnum($translate, CONFIG) {
        return function(input, fieldName) {
            if (fieldName === 'children_number' && Number.isInteger(input)) {
                return input;
            }
        
            if (fieldName == 'seeking_fors') {
                input = input.split('_')[1];
            }
        
            if (fieldName == 'status') {
                fieldName = 'relationship_status';
            }
        
            const fieldConfig = findFieldConfig(fieldName);
        
            if (fieldConfig && fieldConfig.enumTranslationKey) {
                fieldName = fieldConfig.enumTranslationKey;
            } else {
                fieldName = 'enum' + fieldName;
            }
        
            return $translate.instant(fieldName + '_' + input);
        };

        function findFieldConfig (fieldName) {
            let fieldConfig;
        
            CONFIG.profileSections.forEach((section) => {
              let fields = section.fields;
        
              fields.forEach((field) => {
                if (field.name == fieldName) {
                  fieldConfig = field;
                }
              });
            });
        
            return fieldConfig;
        }
    }
}());