'use strict';

angular
    .module('vm')
    .factory('CursorWait', CursorWait);

function CursorWait () {
    return {
        enable: enable,
        disable: disable
    };

    //////////////

    function enable() {
        document.body.classList.add('cursor-wait');
    }

    function disable() {
        document.body.classList.remove('cursor-wait');
    }
}