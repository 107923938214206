angular
    .module('vm.config', [])
    .constant('CONFIG', {
        project: 'vm',
        projectName: 'VictoriaMilan',
        premiumIconColor: 'white',

        profileSections: [
            {
              name: 'appearance',
              translationKey: 'profile.appearance.label',
              icon: 'eye',
              fields: [
                {
                  name: 'height',
                  labelTranslationKey: 'profile.appearance.height'
                },
                {
                  name: 'weight',
                  labelTranslationKey: 'profile.appearance.weight'
                },
                {
                  name: 'eyes',
                  labelTranslationKey: 'profile.appearance.eyes'
                },
                {
                  name: 'hair',
                  labelTranslationKey: 'profile.appearance.hair'
                },
                {
                  name: 'body_type',
                  labelTranslationKey: 'profile.appearance.body_type'
                },
                {
                  name: 'looks',
                  labelTranslationKey: 'profile.appearance.looks'
                },
                {
                  name: 'ethnicity',
                  labelTranslationKey: 'profile.appearance.ethnicity'
                },
                {
                  name: 'best_feature',
                  labelTranslationKey: 'profile.appearance.best_feature'
                },
                {
                  name: 'piercing',
                  labelTranslationKey: 'profile.appearance.piercing'
                },
                {
                  name: 'tattoo',
                  labelTranslationKey: 'profile.appearance.tattoo'
                },
                {
                  name: 'facial_hair',
                  labelTranslationKey: 'profile.appearance.facial_hair'
                }
              ]
            },
            {
              name: 'background_and_lifestyle',
              translationKey: 'profile.background_and_lifestyle',
              icon: 'na',
              fields: [
                {
                  name: 'relationship_status',
                  labelTranslationKey: 'profile.my_details.relationship_status'
                },
                {
                  name: 'smoking',
                  labelTranslationKey: 'profile.my_details.smoking'
                },
                {
                  name: 'drinking',
                  labelTranslationKey: 'profile.my_details.drinking'
                },
                {
                  name: 'exercise_habits',
                  labelTranslationKey: 'profile.my_details.exercise_habits'
                },
                {
                  name: 'occupation',
                  labelTranslationKey: 'profile.my_details.occupation'
                },
                {
                  name: 'education',
                  labelTranslationKey: 'profile.my_details.education'
                },
                {
                  name: 'diet1',
                  labelTranslationKey: 'profile.my_details.diet1'
                },
                {
                  name: 'has_secret_love_nest',
                  labelTranslationKey: 'profile.my_details.has_secret_love_nest'
                },
                {
                  name: 'sex_drive',
                  labelTranslationKey: 'profile.my_details.sex_drive'
                },
                {
                  name: 'orientation',
                  labelTranslationKey: 'profile.my_details.orientation'
                },
                {
                  name: 'languages',
                  multipleUi: true,
                  labelTranslationKey: 'profile.my_details.languages'
                }
              ]
            },
            {
              name: 'personality',
              translationKey: 'profile.details.personality',
              icon: 'information-circled',
              fields: [
                {
                  name: 'interest',
                  multipleUi: true,
                  labelTranslationKey: 'profile.my_details.interest'
                },
                {
                  name: 'personality',
                  multipleUi: true,
                  labelTranslationKey: 'profile.my_details.personality'
                },
                {
                  name: 'turns_on',
                  multipleUi: true,
                  enumTranslationKey: 'enumturnson',
                  labelTranslationKey: 'profile.my_details.turns_on'
                }
              ]
            },
            {
              name: 'seeking',
              translationKey: 'search.seeking_for_title',
              icon: 'na',
              fields: [
                {
                  name: 'seeking_location',
                  labelTranslationKey: 'profile.my_details.seeking_location'
                },
                {
                  name: 'seeking_sex',
                  labelTranslationKey: 'profile.my_details.seeking_sex'
                },
                {
                  name: 'best_match_orientation',
                  labelTranslationKey: 'profile.my_details.seeking_orientation',
                  enumTranslationKey: 'enumorientation',
                  multipleUi: false,
                  multipleBackend: true,
                },
                {
                  name: 'seeking_type_of_relationship',
                  multipleUi: true,
                  enumTranslationKey: 'enumseekingtypeofrelationship',
                  labelTranslationKey: 'profile.my_details.seeking_type_of_relationship'
                }
              ]
            },
            {
              name: 'more_information',
              translationKey: '',
              icon: 'more',
              fields: [
                {
                  name: 'about_you',
                  labelTranslationKey: 'profile.more_information.about_you',
                  placeholder: 'profile.my_details.about_us_placeholder',
                  valid: true,
                },
                {
                  name: 'what_are_you_looking_for',
                  labelTranslationKey: 'profile.more_information.what_are_you_looking_for',
                  placeholder: 'profile.my_details.you_looking_for',
                  valid: true,
                }
              ]
            },
          ],
    })
;