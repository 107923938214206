angular
    .module('vm')
    .component('signUpMay19', {
        templateUrl: 'sign-up-may-19.component.html',
        bindings: {

        },
        controllerAs: 'vm',
        controller: function (signUpMay19Config, $scope, $location, GoogleAnalyticsService, $timeout, SignUpMay19Service) {
            const vm = this;

            vm.steps = signUpMay19Config.steps;
            vm.step = null;
            vm.progress = 0;
            vm.direction = 'forward';
            vm.hasForwardButton = false;

            vm.goBack = goBack;
            vm.goForward = goForward;

            vm.$onInit = activate;

            /////////

            function activate() {
                $scope.$on('$locationChangeSuccess', () => {
                    onLocationChange();
                });

                onLocationChange();

                SignUpMay19Service.addPageLeaveListener();
            }

            function onLocationChange() {
                const stepType = $location.search().step;

                const prevStep = vm.step;
                const nextStep = vm.steps.find((step) => step.type === stepType) || vm.steps[0];

                if (vm.steps.indexOf(prevStep) > vm.steps.indexOf(nextStep)) {
                    vm.direction = 'back';
                } else {
                    vm.direction = 'forward';
                }

                $timeout(() => {
                    vm.step = nextStep;

                    GoogleAnalyticsService.firePageView();

                    updateProgress();

                    updateHasForward(vm.step);
                }, 100);
            }

            function updateProgress() {
                const stepIndex = vm.steps.indexOf(vm.step);

                vm.progress = Math.round((stepIndex / vm.steps.length) * 100);
            }

            function goBack() {
                history.back();
            }

            function goForward() {
                history.forward();
            }

            function updateHasForward(currentStep) {
                vm.hasForwardButton = false;

                const answer = SignUpMay19Service.getStepAnswer(currentStep.type);

                if (answer) {
                    vm.hasForwardButton = true;
                }
            }
        }
    })
;