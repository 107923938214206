angular
    .module('vm')
    .component('signUpMay19Birthday', {
        templateUrl: 'sign-up-may-19-birthday.component.html',
        bindings: {

        },
        controllerAs: 'vm',
        controller: function (SignUpMay19Service, GoogleAnalyticsService, $scope) {
            const vm = this;

            vm.birthday = 'dd.mm.yyyy';
            vm.valid = false;
            vm.ageError = false;

            vm.$onInit = activate;
            vm.submitForm = submitForm;
            vm.validateBirthdate = validateBirthdate;
            vm.stepType = 'birthday';

            ////////////

            function activate() {
                addMask();

                const answer = SignUpMay19Service.getStepAnswer(vm.stepType);
                
                if (answer) {
                    vm.birthday = answer;
                    validateBirthdate();
                }

                $scope.$watch('vm.birthday', validateBirthdate);
            }

            function submitForm() {
                GoogleAnalyticsService.fireEvent(
                    'SignUp May 19', 
                    'birthday'
                );

                SignUpMay19Service.saveStepAnswer(vm.stepType, vm.birthday);
                SignUpMay19Service.goToNextStep(vm.stepType);
            }

            function addMask() {
                const birthdayControl = document.querySelector('.birthday-control');
                IMask(birthdayControl, {
                    mask: Date,
                    // pattern: 'd{-}`m{-}`Y',
                    blocks: {
                        d: {mask: IMask.MaskedRange, placeholderChar: 'd', from: 1, to: 31, maxLength: 2},
                        m: {mask: IMask.MaskedRange, placeholderChar: 'm', from: 1, to: 12, maxLength: 2},
                        Y: {mask: IMask.MaskedRange, placeholderChar: 'y', from: 1900, to: 2020, maxLength: 4}
                    },
                    lazy: false,
                    // overwrite: true,
                    // autofix: true,
                });
            }

            function validateBirthdate() {
                vm.ageError = false;
                vm.valid = moment(vm.birthday, 'DD.MM.YYYY', true).isValid();
                const years = moment().diff(moment(vm.birthday, 'DD.MM.YYYY'), 'years');

                if (years < 18) {
                    vm.valid = false;
                    vm.ageError = true;
                }
            }
        }
    })
;