angular
    .module('vm')
    .component('signUpMay19Email', {
        templateUrl: 'sign-up-may-19-email.component.html',
        bindings: {

        },
        controllerAs: 'vm',
        controller: function ($api, $q, $translate, SignUpMay19Service, GoogleAnalyticsService, Notifications) {
            const vm = this;

            vm.formData = {
                email: '',
                agreeWithEmailMarketing: false,
            };

            vm.submitForm = submitForm;
            vm.$onInit = activate;
            vm.stepType = 'email';

            /////////////

            function activate() {
                vm.formData = SignUpMay19Service.getStepAnswer(vm.stepType);
            }

            function submitForm() {
                if (!vm.formData.agreeWithEmailMarketing) {
                    if (confirm($translate.instant('signup.marketing_email_confirmation'))) {
                        vm.formData.agreeWithEmailMarketing = true;
                    }
                }

                checkEmail()
                    .then(() => {
                        GoogleAnalyticsService.fireEvent(
                            'SignUp May 19', 
                            'email'
                        );

                        SignUpMay19Service.saveStepAnswer(vm.stepType, vm.formData);
                        SignUpMay19Service.goToNextStep(vm.stepType);
                    })
                    .catch((error) => {
                        Notifications.add({
                            text: error,
                            type: 'error'
                        });
                    })
                ;
            }

            function checkEmail() {
                return $q((resolve, reject) => {
                    $api.get('account/check-available-email?email=' + encodeURIComponent(vm.formData.email))
                        .then((response) => {
                            if (response.data.notUnique) {
                                reject(response.data.notUnique);
                            }

                            if (response.data.notDeliverable) {
                                reject(response.data.notDeliverable);
                            }

                            if (response.data.validationError) {
                                reject(response.data.validationError);
                            }

                            resolve();
                        })
                    ;
                });
            }
        }
    })
;