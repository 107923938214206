(function () {
    'use strict';

    angular
        .module('vm')
        .factory('SignUpMay19Service', SignUpMay19Service);

    function SignUpMay19Service (localStorageService, signUpMay19Config, $location, $api) {
        const localStorageKey = 'sign-up-may-19-step-answers';

        return {
            saveStepAnswer: saveStepAnswer,
            getStepAnswer: getStepAnswer,
            goToNextStep: goToNextStep,
            sendSignUpForm: sendSignUpForm,
            removeAnswers: removeAnswers,
            getSignUpFormData: getSignUpFormData,
            addPageLeaveListener: addPageLeaveListener,
            removePageLeaveListener: removePageLeaveListener
        };

        //////////////

        function saveStepAnswer(stepType, answer) {
            const stepAnswers = getStepAnswers();

            stepAnswers[stepType] = answer;

            localStorageService.set(localStorageKey, stepAnswers);
        }

        function goToNextStep(currentStep) {
            const steps = signUpMay19Config.steps;

            for (const step of steps) {
                if (step.type === currentStep) {
                    const nextStep = steps[steps.indexOf(step) + 1];

                    if (nextStep) {
                        $location.search('step', nextStep.type);
                        break;
                    }
                }
            }
        }

        function sendSignUpForm() {
            const formData = getSignUpFormData();

            const data = {
                api_signup_by_group: formData,
            };

            return $api.post('account/signup-by-group', data);
        }

        function getSignUpFormData() {
            const formData = {};

            const answers = getStepAnswers();

            formData.group = 'new_signup_may19';
            formData.name = answers.username.username;
            formData.email = answers.email.email;
            formData.sex = answers.gender.sex;
            formData.seekingSex = answers.gender.seekingSex;
            formData.relationshipStatus = answers.relationship_status;
            formData.password = answers.password.password;
            formData.birthDate = moment(answers.birthday, 'DD.MM.YYYY').format('YYYY-MM-DD');
            formData.agreeWithTerms = answers.acceptTerms.agreeWithTerms;
            formData.agreeWithEmailMarketing = answers.email.agreeWithEmailMarketing;
            formData.height = answers.height;
            formData.weight = answers.weight;
            formData.eyes = answers.eyes;
            formData.hair = answers.hair;
            formData.bodyType = answers.body_type;
            formData.looks = answers.looks;
            formData.registerCampaignId = answers.organic;
            formData.city_id = answers.location.id;

            return formData;
        }

        function getStepAnswer(stepType) {
            const stepAnswers = getStepAnswers();

            return stepAnswers[stepType];
        }

        function getStepAnswers() {
            let stepAnswers = localStorageService.get(localStorageKey);

            if (!stepAnswers) {
                stepAnswers = {}; 
            }

            return stepAnswers;
        }

        function removeAnswers() {
            localStorageService.remove(localStorageKey);
        }

        function addPageLeaveListener() {
            window.addEventListener('beforeunload', onPageLeave);
        }

        function removePageLeaveListener() {
            window.removeEventListener('beforeunload', onPageLeave);
        }

        function onPageLeave(event) {
            event.preventDefault();
            event.returnValue = '';
        }
    }
}());