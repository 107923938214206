(function () {
    'use strict';

    angular
        .module('vm.common')
        .factory('VmcPromoService', VmcPromoService)
    ;

    function VmcPromoService(DomainSettings) {
        const countries = {
            'INTL': {
                sum: '10.000 kr.',
                url: 'https://www.victoriamilancasino.com/',
                locale: 'nb',
            },
            'FI': {
                sum: '€700',
                url: 'https://www.victoriamilancasino.com/',
                local: 'fi',
            },
            'DK': {
                sum: '10.000 kr.',
                url: 'https://www.victoriamilancasino.dk/',
                locale: 'da',
            }
        };

        return {
            getCountry: getCountry,
        };

        //////////////

        function getCountry() {
            return DomainSettings.get()
                .then((domainSettings) => {
                    const domainCountry = domainSettings.domainCode.split('_')[1];

                    let country = countries[domainCountry];

                    if (!country) {
                        country = countries[domainSettings.ipCountryCode];
                    }

                    return country;
                })
            ;
        }
    }
}());