'use strict';

angular.module('vm', ['vcRecaptcha', 'pascalprecht.translate', 'mgcrea.ngStrap', 'ngAnimate', 'ngCookies', 'ngSanitize', 'ngPasswordMeter', 'ui.bootstrap', 'vm.common', 'vm.config', 'ui.select', 'LocalStorageModule']).config(function ($translateProvider) {
    $translateProvider.fallbackLanguage('en');
}).filter('passwordToAsterisk', function () {
    return function (input) {
        var output = '';

        for (var i = 0; i <= input.length; i++) {
            output += '*';
        }

        return output;
    };
}).run(function ($injector) {
    window.injector = $injector;
});
'use strict';

(function () {
    'use strict';

    angular.module('vm').factory('LangSelectorModal', LangSelectorModal);

    function LangSelectorModal($modal, $rootScope, $http) {
        var modalScope = $rootScope.$new(true);
        var vm = modalScope.vm = {};

        vm.domains = null;
        vm.changeLanguage = changeLanguage;

        return {
            show: show
        };

        //////////////

        function show() {
            getDomains();

            $modal({
                templateUrl: 'lang-selector-modal.html',
                show: true,
                scope: modalScope
            });
        }

        function getDomains() {
            $http.get('/api/v4.0/service/domain-links.json?type=mweb').then(function (response) {
                vm.domains = response.data;
            });
        }

        function changeLanguage(locale) {
            var newUrl,
                curUrl = window.location.href;

            if (/\/([a-z]{2}|[a-z]{2}_[A-Z]{2}|zh_Hans_[A-Z]{2})\//.test(curUrl)) {
                newUrl = curUrl.replace(/\/([a-z]{2}|[a-z]{2}_[A-Z]{2}|zh_Hans_[A-Z]{2})\//g, '/' + locale + '/');
            } else {
                newUrl = curUrl.replace(/^(https?.\/\/(\w+\.)+\w+)\/(.*)$/, '$1/' + locale + '/$3');
            }

            window.location.href = newUrl;
        }
    }
})();
'use strict';

(function () {
    'use strict';

    angular.module('vm').factory('SignupFetchErrorMessage', SignupFetchErrorMessage);

    function SignupFetchErrorMessage($translate) {
        return function (response) {
            var message = '';

            if (angular.isDefined(response.errors)) {
                if (response.errors === null) {
                    message = response.message;
                } else if (angular.isString(response.errors)) {
                    message = response.errors;
                } else {
                    angular.forEach(response.errors.children, function (value, key) {
                        if (angular.isDefined(value.errors)) {
                            message += {
                                "name": $translate.instant('common.username_label') + ": ",
                                "email": $translate.instant('common.email') + ": ",
                                "password": $translate.instant('common.password_label') + ": ",
                                "sex": "Sex: ",
                                "birthDate": $translate.instant('common.birthday_label') + ": "
                            }[key];

                            message += value.errors.join(' ') + '<br/>';
                        }
                    });

                    if (angular.isDefined(response.errors.errors)) {
                        message += response.errors.errors.join('');
                    }
                }
            }

            return message;
        };
    }
})();
'use strict';

(function () {
    'use strict';

    angular.module('vm').factory('DomainSettings', DomainSettings);

    function DomainSettings($http, CONFIG) {
        var domainSettingsPromise = void 0;

        return {
            clean: clean,
            get: get,
            reload: reload
        };

        //////////////

        function clean() {
            domainSettingsPromise = null;
        }

        function get() {
            if (!domainSettingsPromise) {
                domainSettingsPromise = $http.get('/api/v4.0/service/domain-settings.json').then(function (response) {
                    return angular.extend({}, CONFIG.defaultDomainSettings, response.data);
                }).catch(function () {
                    return CONFIG.defaultDomainSettings;
                });
            }

            return domainSettingsPromise;
        }

        function reload() {
            clean();
            get();
        }
    }
})();
'use strict';

(function () {
    'use strict';

    angular.module('vm').factory('BrowserOauth', BrowserOauth);

    function BrowserOauth($q, DOMAIN_SETTINGS, CONFIG) {
        return {
            auth: auth,
            getAuthUrl: getAuthUrl
        };

        //////////////

        function auth(provider) {
            return new $q(function (resolve, reject) {
                getAuthUrl(provider).then(function (authUrl) {
                    var authWindow = window.open(authUrl);

                    var interval = setInterval(function () {
                        try {
                            var url = authWindow.location.href;

                            if (url && url.includes('/oauth.html')) {
                                var callbackResponse = authWindow.location.hash.split('#')[1];
                                var responseParameters = callbackResponse.split('&');
                                var parameterMap = {};

                                for (var i = 0; i < responseParameters.length; i++) {
                                    parameterMap[responseParameters[i].split('=')[0]] = responseParameters[i].split('=')[1];
                                }

                                clearInterval(interval);
                                authWindow.close();

                                if (parameterMap.access_token) {
                                    var token = {
                                        access_token: parameterMap.access_token,
                                        expires_in: parseInt(parameterMap.expires_in),
                                        created_at: new Date()
                                    };

                                    resolve(token);
                                } else {
                                    reject('problem authenticating');
                                }
                            }
                        } catch (e) {}
                    }, 1000);
                });
            });
        }

        function getAuthUrl(provider) {
            var redirectLocation = [window.location.protocol, '//', window.location.host, '/oauth.html'].join('');

            return new $q(function (resolve) {
                var providers = {
                    facebook: {
                        authenticate: 'https://www.facebook.com/v2.0/dialog/oauth?client_id=' + DOMAIN_SETTINGS.facebookClientId + '&redirect_uri=' + redirectLocation + '&response_type=token&scope=' + CONFIG.oAuthProviders.facebook.appScope.join(',')
                    },
                    instagram: {
                        authenticate: 'https://api.instagram.com/oauth/authorize/?client_id=' + DOMAIN_SETTINGS.instagramClientId + '&redirect_uri=' + redirectLocation + '&response_type=token&scope=' + CONFIG.oAuthProviders.instagram.appScope.join('+')
                    },
                    windows_live: {
                        authenticate: 'https://login.live.com/oauth20_authorize.srf?client_id=' + DOMAIN_SETTINGS.windowsLiveClientId + "&scope=" + CONFIG.oAuthProviders.windowsLive.appScope.join('+') + '&response_type=token&display=touch&redirect_uri=' + redirectLocation
                    },
                    google: {
                        authenticate: 'https://accounts.google.com/o/oauth2/auth?client_id=' + DOMAIN_SETTINGS.googleClientId + '&redirect_uri=' + redirectLocation + '&scope=' + CONFIG.oAuthProviders.google.appScope.join(' ') + '&approval_prompt=force&response_type=token'
                    }
                };

                resolve(providers[provider].authenticate);
            });
        }
    }
})();
'use strict';

angular.module('vm').factory('CursorWait', CursorWait);

function CursorWait() {
    return {
        enable: enable,
        disable: disable
    };

    //////////////

    function enable() {
        document.body.classList.add('cursor-wait');
    }

    function disable() {
        document.body.classList.remove('cursor-wait');
    }
}
'use strict';

angular.module('vm').factory('DateSelect', DateSelect);

function DateSelect($translate) {
    return {
        getDays: getDays,
        getMonths: getMonths,
        getYears: getYears
    };

    //////////////

    function getDays(maxDate) {
        var days = [];

        if (!maxDate) {
            var maxDate = 31;
        }

        for (var day = 1; day <= maxDate; day++) {
            var fullDay = day.toString();

            if (day < 10) {
                fullDay = '0' + fullDay;
            }

            days.push({
                id: fullDay,
                text: fullDay
            });
        }

        return days;
    }

    function getMonths() {
        return [{
            id: '01',
            text: $translate.instant('common.date.january')
        }, {
            id: '02',
            text: $translate.instant('common.date.february')
        }, {
            id: '03',
            text: $translate.instant('common.date.march')
        }, {
            id: '04',
            text: $translate.instant('common.date.april')
        }, {
            id: '05',
            text: $translate.instant('common.date.may')
        }, {
            id: '06',
            text: $translate.instant('common.date.june')
        }, {
            id: '07',
            text: $translate.instant('common.date.july')
        }, {
            id: '08',
            text: $translate.instant('common.date.august')
        }, {
            id: '09',
            text: $translate.instant('common.date.september')
        }, {
            id: '10',
            text: $translate.instant('common.date.october')
        }, {
            id: '11',
            text: $translate.instant('common.date.november')
        }, {
            id: '12',
            text: $translate.instant('common.date.december')
        }];
    }

    function getYears() {
        var years = [];
        var now = new Date();
        var minYear = now.getFullYear() - 100;
        var maxYear = now.getFullYear() - 18;

        for (var year = maxYear; year >= minYear; year--) {
            years.push({
                id: '' + year,
                text: '' + year
            });
        }

        return years;
    }
}
'use strict';

angular.module('vm').factory('RelationshipStatusSelect', RelationshipStatusSelect);

function RelationshipStatusSelect($translate) {
    var MARRIED_MAN_SEEKING_WOMAN = 0,
        MARRIED_WOMAN_SEEKING_MAN = 1,
        ATTACHED_MAN_SEEKING_WOMAN = 2,
        ATTACHED_WOMAN_SEEKING_MAN = 3,
        SINGLE_MAN_SEEKING_WOMAN = 4,
        SINGLE_WOMAN_SEEKING_MAN = 5,
        MAN_SEEKING_MAN = 6,
        WOMAN_SEEKING_WOMAN = 7,
        DIVORCED_MAN_SEEKING_WOMAN = 8,
        DIVORCED_WOMAN_SEEKING_MAN = 9;

    var SEX_MALE = 'male',
        SEX_FEMALE = 'female';

    var SINGLE = 'single',
        SEPARATED = 'separated',
        MARRIED = 'married',
        DIVORCED = 'divorced',
        WIDOWED = 'widowed',
        ATTACHED = 'attached';

    return {
        getUserDefinitionVM: getUserDefinitionVM,
        getUserDefinitionMap: getUserDefinitionMap
    };

    //////////////

    function getUserDefinitionVM() {
        return [{
            id: MARRIED_MAN_SEEKING_WOMAN,
            text: $translate.instant('married_man_seeking_a_woman'),
            sex: 'man'
        }, {
            id: MARRIED_WOMAN_SEEKING_MAN,
            text: $translate.instant('married_woman_seeking_a_man'),
            sex: 'woman'
        }, {
            id: ATTACHED_MAN_SEEKING_WOMAN,
            text: $translate.instant('attached_man_seeking_a_woman'),
            sex: 'man'
        }, {
            id: ATTACHED_WOMAN_SEEKING_MAN,
            text: $translate.instant('attached_woman_seeking_a_man'),
            sex: 'woman'
        }, {
            id: SINGLE_MAN_SEEKING_WOMAN,
            text: $translate.instant('single_man_seeking_a_woman'),
            sex: 'man'
        }, {
            id: SINGLE_WOMAN_SEEKING_MAN,
            text: $translate.instant('single_woman_seeking_a_man'),
            sex: 'woman'
        }, {
            id: MAN_SEEKING_MAN,
            text: $translate.instant('man_seeking_a_man'),
            sex: 'man'
        }, {
            id: WOMAN_SEEKING_WOMAN,
            text: $translate.instant('woman_seeking_a_woman'),
            sex: 'woman'
        }];
    }

    function getUserDefinitionNL() {
        return [];
    }

    function getUserDefinitionMap() {
        return [MARRIED_MAN_SEEKING_WOMAN = {
            'sex': SEX_MALE,
            'seekingSex': SEX_FEMALE,
            'relationshipStatus': MARRIED
        }, MARRIED_WOMAN_SEEKING_MAN = {
            'sex': SEX_FEMALE,
            'seekingSex': SEX_MALE,
            'relationshipStatus': MARRIED
        }, ATTACHED_MAN_SEEKING_WOMAN = {
            'sex': SEX_MALE,
            'seekingSex': SEX_FEMALE,
            'relationshipStatus': ATTACHED
        }, ATTACHED_WOMAN_SEEKING_MAN = {
            'sex': SEX_FEMALE,
            'seekingSex': SEX_MALE,
            'relationshipStatus': ATTACHED
        }, SINGLE_MAN_SEEKING_WOMAN = {
            'sex': SEX_MALE,
            'seekingSex': SEX_FEMALE,
            'relationshipStatus': SINGLE
        }, SINGLE_WOMAN_SEEKING_MAN = {
            'sex': SEX_FEMALE,
            'seekingSex': SEX_MALE,
            'relationshipStatus': SINGLE
        }, MAN_SEEKING_MAN = {
            'sex': SEX_MALE,
            'seekingSex': SEX_MALE,
            'relationshipStatus': SINGLE
        }, WOMAN_SEEKING_WOMAN = {
            'sex': SEX_FEMALE,
            'seekingSex': SEX_FEMALE,
            'relationshipStatus': SINGLE
        }, DIVORCED_MAN_SEEKING_WOMAN = {
            'sex': SEX_MALE,
            'seekingSex': SEX_FEMALE,
            'relationshipStatus': DIVORCED
        }, DIVORCED_WOMAN_SEEKING_MAN = {
            'sex': SEX_FEMALE,
            'seekingSex': SEX_MALE,
            'relationshipStatus': DIVORCED
        }];
    }
}
'use strict';

(function () {
    'use strict';

    angular.module('vm').factory('Auth', Auth);

    function Auth($api, BrowserOauth, $cookies) {
        return {
            oAuthLogin: oAuthLogin,
            redirectToFrontend: redirectToFrontend
        };

        //////////////

        function oAuthLogin(provider) {
            return BrowserOauth.auth(provider).then(function (token) {
                var url = 'oauth-hybrid-auth.json' + '?service=' + provider + '&token=' + token.access_token + '&expirationTime=' + token.expires_in;

                return $api.get(url).then(function (response) {
                    return response.data;
                });
            });
        }

        function redirectToFrontend(token) {
            var cookieOptions = {
                path: '/',
                expires: new Date(new Date().setFullYear(new Date().getFullYear() + 5))
            };

            try {
                $cookies.remove('preferV4', cookieOptions);
                $cookies.remove('preferMobile', cookieOptions);
            } catch (e) {}

            // if (getScreenWidth() < 1000) {
            //     $cookies.put('preferMobile', 'true', cookieOptions);
            //
            //     window.location.href = '/?nc#/auto-login?token=' + token;
            // }
            // else {
            $cookies.put('preferV4', 'true', cookieOptions);

            window.location.href = '/?nc#/auto-login;token=' + token;
            // }
        }

        function getScreenWidth() {
            try {
                return window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
            } catch (err) {
                console.error(err);
                return 0;
            }
        }
    }
})();
'use strict';

angular.module('vm').component('appLangSelector', {
    templateUrl: 'lang-selector.component.html',
    bindings: {},
    controllerAs: 'vm',
    controller: function controller(LangSelectorModal) {
        var vm = this;

        vm.showLangSelector = showLangSelector;

        /////////////

        function showLangSelector() {
            LangSelectorModal.show();
        }
    }
});
'use strict';

angular.module('vm').component('appCookieAgreement', {
    templateUrl: 'cookie-agreement.component.html',
    bindings: {},
    controllerAs: 'vm',
    controller: function controller($cookies) {
        var vm = this;

        vm.step = null;
        vm.$onInit = $onInit;
        vm.onReadMore = onReadMore;
        vm.onNotNow = onNotNow;
        vm.onIAgree = onIAgree;

        function $onInit() {
            var agreeWithCookie = $cookies.get('agreed_with_cookie_policy');

            if (!agreeWithCookie) {
                vm.step = 'show';
            }
        }

        function onReadMore() {
            vm.step = 'show';
        }

        function onNotNow() {
            vm.step = 'notnow';
        }

        function onIAgree() {
            var now = new Date(),
                exp = new Date(now.getFullYear() + 10, now.getMonth(), now.getDate());
            var unix = Math.round(+new Date() / 1000);

            $cookies.put('agreed_with_cookie_policy', unix, {
                expires: exp
            });

            vm.step = null;
        }
    }
});
"use strict";

var LandingTracking = {
    data: {},

    initialize: function initialize() {
        this.collectData();
        this.sendPixel();
        this.updateForms();
    },

    collectData: function collectData() {
        // 1. REQUEST_URI = /welcome/adult?cid=test_cid&pid=somepid
        // 2. SERVER_NAME = some.name.com
        // 3. HTTP_REFERER = http://some.name.com/sdkksa/ccdd?something=ok

        this.data['referrer'] = encodeURIComponent(document.referrer);
        this.data['server_name'] = encodeURIComponent(document.location.hostname);

        var href = document.location.href;
        var len = document.location.protocol.length + 2 + document.location.hostname.length;
        var uri = href.substring(len);
        uri = uri.replace(/.*?(\/.*)/, '$1');

        this.data['request_uri'] = uri;
    },

    findFormItems: function findFormItems() {
        var result = [];
        var items = document.getElementsByName('nextlove_user_short_registration_form[_token]');
        if (items.length > 0) {
            items.forEach(function (item) {
                result.push(item);
            });
        }

        items = document.getElementsByName('nextlove_user_short_registration_form[_token]');
        if (items.length > 0) {
            items.forEach(function (item) {
                result.push(item);
            });
        }

        items = document.getElementsByName('footer_nextlove_user_short_registration_form[_token]');
        if (items.length > 0) {
            items.forEach(function (item) {
                result.push(item);
            });
        }

        return result;
    },

    onTrackingInitialized: function onTrackingInitialized() {
        var event = document.createEvent('Event');
        event.state = 4;
        event.initEvent('digisec:on-tracking-executed', true, false);
        window.dispatchEvent(event);
    },

    updateForms: function updateForms() {
        var self = this;
        setTimeout(function () {

            var inputs = self.findFormItems();
            inputs.forEach(function (input) {
                var name = input.name.replace(/(.*)\[.*\]/, '$1');
                var http = new XMLHttpRequest();
                http.open("POST", '/forms/token/' + name, true);
                http.setRequestHeader("Content-type", "application/json;charset=UTF-8");
                http.onreadystatechange = function () {
                    if (http.readyState === 4) {
                        var response = JSON.parse(http.response);
                        input.value = response.token;
                    }
                };
                http.send();
            });
        }, 100);
    },

    sendPixel: function sendPixel() {
        var self = this,
            http = new XMLHttpRequest();
        http.open("POST", '/api/v4.0/settings/track', true);
        http.setRequestHeader("Content-type", "application/json;charset=UTF-8");
        http.onreadystatechange = function () {
            if (http.readyState === 4) {
                var response = JSON.parse(http.response);
                self.changeLocale(response.locale);
                self.onTrackingInitialized();
            }
        };
        http.send(JSON.stringify(this.data));
    },

    changeLocale: function changeLocale(locale) {
        var newUrl,
            curUrl = window.location.pathname,
            match = curUrl.match(/^\/([a-z]{2}|[a-z]{2}_[A-Z]{2}|zh_Hans_[A-Z]{2})\//);

        if (!curUrl.match(/^\/(([a-z]{2}|[a-z]{2}_[A-Z]{2}|zh_Hans_[A-Z]{2})\/)?(land|blog).*/)) {
            return;
        }

        if (match) {
            if (match[1] === locale) {
                return false;
            }
            newUrl = curUrl.replace(/\/([a-z]{2}|[a-z]{2}_[A-Z]{2}|zh_Hans_[A-Z]{2})\//g, '/' + locale + '/');
        } else {
            newUrl = curUrl.replace(/^(https?.\/\/(\w+\.)+\w+)\/(.*)$/, '$1/' + locale + '/$3');
        }

        window.location.href = newUrl;
    }
};

LandingTracking.initialize();
"use strict";

var LandingAbTest = {
    data: {},

    initialize: function initialize() {
        var self = this;
        window.addEventListener('digisec:on-tracking-executed', function () {
            self.abTestInit();
        });
    },

    abTestInit: function abTestInit() {
        var self = this,
            http = new XMLHttpRequest();
        http.open("POST", '/api/v4.0/settings/lander-ab-test', true);
        http.setRequestHeader("Content-type", "application/json;charset=UTF-8");
        http.onreadystatechange = function () {
            if (http.readyState === 4) {
                var response = JSON.parse(http.response);
                console.log(response);

                if (response['js']) {
                    eval(response['js']);
                }
            }
        };
        http.send();
    }
};

LandingAbTest.initialize();
'use strict';

angular.module('vm.config', []).constant('CONFIG', {
  project: 'vm',
  projectName: 'VictoriaMilan',
  premiumIconColor: 'white',

  profileSections: [{
    name: 'appearance',
    translationKey: 'profile.appearance.label',
    icon: 'eye',
    fields: [{
      name: 'height',
      labelTranslationKey: 'profile.appearance.height'
    }, {
      name: 'weight',
      labelTranslationKey: 'profile.appearance.weight'
    }, {
      name: 'eyes',
      labelTranslationKey: 'profile.appearance.eyes'
    }, {
      name: 'hair',
      labelTranslationKey: 'profile.appearance.hair'
    }, {
      name: 'body_type',
      labelTranslationKey: 'profile.appearance.body_type'
    }, {
      name: 'looks',
      labelTranslationKey: 'profile.appearance.looks'
    }, {
      name: 'ethnicity',
      labelTranslationKey: 'profile.appearance.ethnicity'
    }, {
      name: 'best_feature',
      labelTranslationKey: 'profile.appearance.best_feature'
    }, {
      name: 'piercing',
      labelTranslationKey: 'profile.appearance.piercing'
    }, {
      name: 'tattoo',
      labelTranslationKey: 'profile.appearance.tattoo'
    }, {
      name: 'facial_hair',
      labelTranslationKey: 'profile.appearance.facial_hair'
    }]
  }, {
    name: 'background_and_lifestyle',
    translationKey: 'profile.background_and_lifestyle',
    icon: 'na',
    fields: [{
      name: 'relationship_status',
      labelTranslationKey: 'profile.my_details.relationship_status'
    }, {
      name: 'smoking',
      labelTranslationKey: 'profile.my_details.smoking'
    }, {
      name: 'drinking',
      labelTranslationKey: 'profile.my_details.drinking'
    }, {
      name: 'exercise_habits',
      labelTranslationKey: 'profile.my_details.exercise_habits'
    }, {
      name: 'occupation',
      labelTranslationKey: 'profile.my_details.occupation'
    }, {
      name: 'education',
      labelTranslationKey: 'profile.my_details.education'
    }, {
      name: 'diet1',
      labelTranslationKey: 'profile.my_details.diet1'
    }, {
      name: 'has_secret_love_nest',
      labelTranslationKey: 'profile.my_details.has_secret_love_nest'
    }, {
      name: 'sex_drive',
      labelTranslationKey: 'profile.my_details.sex_drive'
    }, {
      name: 'orientation',
      labelTranslationKey: 'profile.my_details.orientation'
    }, {
      name: 'languages',
      multipleUi: true,
      labelTranslationKey: 'profile.my_details.languages'
    }]
  }, {
    name: 'personality',
    translationKey: 'profile.details.personality',
    icon: 'information-circled',
    fields: [{
      name: 'interest',
      multipleUi: true,
      labelTranslationKey: 'profile.my_details.interest'
    }, {
      name: 'personality',
      multipleUi: true,
      labelTranslationKey: 'profile.my_details.personality'
    }, {
      name: 'turns_on',
      multipleUi: true,
      enumTranslationKey: 'enumturnson',
      labelTranslationKey: 'profile.my_details.turns_on'
    }]
  }, {
    name: 'seeking',
    translationKey: 'search.seeking_for_title',
    icon: 'na',
    fields: [{
      name: 'seeking_location',
      labelTranslationKey: 'profile.my_details.seeking_location'
    }, {
      name: 'seeking_sex',
      labelTranslationKey: 'profile.my_details.seeking_sex'
    }, {
      name: 'best_match_orientation',
      labelTranslationKey: 'profile.my_details.seeking_orientation',
      enumTranslationKey: 'enumorientation',
      multipleUi: false,
      multipleBackend: true
    }, {
      name: 'seeking_type_of_relationship',
      multipleUi: true,
      enumTranslationKey: 'enumseekingtypeofrelationship',
      labelTranslationKey: 'profile.my_details.seeking_type_of_relationship'
    }]
  }, {
    name: 'more_information',
    translationKey: '',
    icon: 'more',
    fields: [{
      name: 'about_you',
      labelTranslationKey: 'profile.more_information.about_you',
      placeholder: 'profile.my_details.about_us_placeholder',
      valid: true
    }, {
      name: 'what_are_you_looking_for',
      labelTranslationKey: 'profile.more_information.what_are_you_looking_for',
      placeholder: 'profile.my_details.you_looking_for',
      valid: true
    }]
  }]
});
'use strict';

angular.module('vm.common', ['ngCookies']);
'use strict';

(function () {
    'use strict';

    angular.module('vm.common').component('appBanner', {
        templateUrl: 'app-banner.component.html',
        bindings: {
            type: '='
        },
        controllerAs: 'vm',
        controller: function controller(CONFIG) {
            var vm = this;

            vm.CONFIG = CONFIG;
            vm.showBanner = true;

            vm.close = close;

            //////////

            function close() {
                vm.showBanner = false;

                var els = document.querySelectorAll('.has-app-banner');

                if (els) {
                    var _iteratorNormalCompletion = true;
                    var _didIteratorError = false;
                    var _iteratorError = undefined;

                    try {
                        for (var _iterator = els[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
                            var el = _step.value;

                            el.classList.remove('has-app-banner');
                        }
                    } catch (err) {
                        _didIteratorError = true;
                        _iteratorError = err;
                    } finally {
                        try {
                            if (!_iteratorNormalCompletion && _iterator.return) {
                                _iterator.return();
                            }
                        } finally {
                            if (_didIteratorError) {
                                throw _iteratorError;
                            }
                        }
                    }
                }
            }
        }
    });
})();
'use strict';

(function () {
    'use strict';

    angular.module('vm.common').component('crossSellingModal', {
        templateUrl: 'cross-selling-modal.component.html',
        bindings: {},
        controllerAs: 'vm',
        controller: function controller(CONFIG, $api, Auth, CursorWait, $translate) {
            var vm = this;

            vm.showModal = false;
            vm.project = CONFIG.project;
            vm.projectName = CONFIG.projectName;
            vm.username = null;
            vm.donorProjectName = null;
            vm.formSubmitted = false;

            vm.translationKeys = {
                vm: {
                    text1: 'vm_layout.dating.top_title',
                    text2: 'gold_lander.member_count'
                },
                rmb: {
                    text1: 'rmb.relationships_on_your_terms',
                    text2: 'rmb.find_a_sugarbaby_or_sugardady_in_your_area_today'
                },
                nl: {
                    text1: 'nextlove.social_network',
                    text2: 'vm_layout.index.top_bullet_2'
                }
            };

            vm.activateProfile = activateProfile;

            activate();

            /////////////

            function activate() {
                var crossHash = getParameterByName('cross_hash');

                if (crossHash) {
                    $api.get('cross-selling/check/' + crossHash).then(function (response) {
                        if (response.data.project) {
                            vm.donorProjectName = response.data.project;
                            vm.username = response.data.username;
                            vm.showModal = true;
                        }
                    });
                }
            }

            function activateProfile() {
                var crossHash = getParameterByName('cross_hash');

                if (vm.formSubmitted) {
                    return;
                }

                vm.formSubmitted = true;

                CursorWait.enable();

                $api.post('cross-selling/signup/' + crossHash).then(function (response) {
                    Auth.redirectToFrontend(response.data.token);
                }).catch(function () {
                    CursorWait.disable();
                    alert($translate.instant('error.something_went_wrong'));
                    vm.formSubmitted = false;
                });
            }

            function getParameterByName(name) {
                var url = window.location.href;
                name = name.replace(/[\[\]]/g, '\\$&');
                var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
                    results = regex.exec(url);
                if (!results) return null;
                if (!results[2]) return '';
                return decodeURIComponent(results[2].replace(/\+/g, ' '));
            }
        }
    });
})();
'use strict';

(function () {
    'use strict';

    angular.module('vm.common').component('appNotification', {
        templateUrl: 'notification.component.html',
        bindings: {
            notification: '=',
            close: '&'
        },
        controllerAs: 'vm',
        controller: function controller() {
            var vm = this;

            vm.onClose = onClose;

            //////////

            function onClose() {
                vm.close(vm.notification);
            }
        }
    });
})();
'use strict';

(function () {
    'use strict';

    angular.module('vm.common').component('appNotifications', {
        templateUrl: 'notifications.component.html',
        bindings: {},
        controllerAs: 'vm',
        controller: function controller(Notifications, $timeout) {
            var vm = this;

            vm.notifications = [];
            vm.$onInit = $onInit;
            vm.removeNotification = removeNotification;

            //////////

            function $onInit() {
                Notifications.setOnNotificationAdd(onNotificationAdd.bind(this));
            }

            function onNotificationAdd(notification) {
                vm.notifications.push(notification);

                $timeout(function () {
                    removeNotification(notification);
                }, 5000);
            }

            function removeNotification(notification) {
                vm.notifications.splice(vm.notifications.indexOf(notification), 1);
            }
        }
    });
})();
'use strict';

(function () {
    'use strict';

    angular.module('vm.common').component('appVmcSlider', {
        templateUrl: 'vmc-slider.component.html',
        bindings: {},
        controllerAs: 'vm',
        controller: function controller($timeout, ResponsiveService) {
            var vm = this;

            vm.enabled = false;
            vm.active = false;
            vm.country = null;

            vm.adbutlerId = 175289;
            vm.width = 450;
            vm.height = 195;
            vm.divId = null;

            var zones = {
                // 'mobile_retina': 352882,
                // 'mobile_non_retina': 352890,
                'desktop_retina': 352893,
                'desktop_non_retina': 352892
            };

            activate();

            ////////////////

            function activate() {
                var zoneId = getZoneId();

                if (zoneId) {
                    injectBanner(zoneId);

                    window.addEventListener('adbutlerOnResponse', function (data) {
                        if (data.detail.filled) {
                            vm.enabled = true;

                            $timeout(function () {
                                vm.active = true;
                            }, 3000);
                        }
                    });
                }
            }

            function getZoneId() {
                var key = ResponsiveService.isMobile() ? 'mobile' : 'desktop';
                key += '_';
                key += ResponsiveService.isRetina() ? 'retina' : 'non_retina';

                return zones[key];
            }

            function injectBanner(zoneId) {
                if (!window.AdButler) {
                    (function () {
                        var s = document.createElement("script");
                        s.async = true;
                        s.type = "text/javascript";
                        s.src = 'https://servedbyadbutler.com/app.js';
                        var n = document.getElementsByTagName("script")[0];
                        n.parentNode.insertBefore(s, n);
                    })();
                }

                window.AdButler = window.AdButler || {};
                window.abkw = window.abkw || '';
                window['plc' + zoneId] = window['plc' + zoneId] || 0;

                window.AdButler.ads = AdButler.ads || [];
                var plc = window['plc' + zoneId];

                vm.divId = 'placement_' + zoneId + '_' + plc;

                window.AdButler.ads.push({
                    handler: function handler(opt) {
                        window.AdButler.register(vm.adbutlerId, zoneId, [vm.width, vm.height], 'placement_' + zoneId + '_' + opt.place, opt);
                    },
                    opt: {
                        place: window['plc' + zoneId]++,
                        keywords: window.abkw,
                        domain: 'servedbyadbutler.com',
                        click: 'CLICK_MACRO_PLACEHOLDER'
                    }
                });
            }
        }
    });
})();
'use strict';

(function () {

    angular.module('vm.common').service('$api', $api);

    function $api($http) {

        return {
            get: get,
            delete: _delete,
            patch: patch,
            post: post,
            put: put,
            getFullUrl: getFullUrl
        };

        /////////////////

        function get(path, options) {
            return $http.get(getFullUrl(path), options);
        }

        function post(path, parameters, options) {
            return $http.post(getFullUrl(path), parameters, options);
        }

        function _delete(path) {
            return $http.delete(getFullUrl(path));
        }

        function patch(path, parameters, options) {
            return $http.patch(getFullUrl(path), parameters, options);
        }

        function put(path, parameters, options) {
            return $http.put(getFullUrl(path), parameters, options);
        }

        function getFullUrl(path) {
            return '/api/v4.0/' + path;
        }
    }
})();
'use strict';

(function () {
    'use strict';

    angular.module('vm.common').factory('Notifications', Notifications);

    function Notifications() {
        var onNotificationAdd;

        return {
            add: add,
            setOnNotificationAdd: setOnNotificationAdd
        };

        //////////////

        function add(notificationData) {
            if (onNotificationAdd) {
                onNotificationAdd(notificationData);
            }
        }

        function setOnNotificationAdd(fn) {
            onNotificationAdd = fn;
        }
    }
})();
'use strict';

(function () {
    'use strict';

    angular.module('vm.common').factory('ResponsiveService', ResponsiveService);

    function ResponsiveService() {
        return {
            getScreenWidth: getScreenWidth,
            isMobile: isMobile,
            isRetina: isRetina
        };

        //////////////

        function isMobile() {
            return getScreenWidth() < 500;
        }

        function isRetina() {
            return window.devicePixelRatio > 1;
        }

        function getScreenWidth() {
            try {
                return window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
            } catch (err) {
                console.error(err);
                return 0;
            }
        }
    }
})();
'use strict';

(function () {
    'use strict';

    angular.module('vm').factory('UrlSearchParamsService', UrlSearchParamsService);

    function UrlSearchParamsService() {
        var urlSearchParams = new URLSearchParams(window.location.search);

        return {
            get: get,
            has: has
        };

        //////////////

        function get(param) {
            return urlSearchParams.get(param);
        }

        function has(param) {
            return urlSearchParams.has(param);
        }
    }
})();
'use strict';

(function () {
    'use strict';

    angular.module('vm.common').factory('VmcPromoService', VmcPromoService);

    function VmcPromoService(DomainSettings) {
        var countries = {
            'INTL': {
                sum: '10.000 kr.',
                url: 'https://www.victoriamilancasino.com/',
                locale: 'nb'
            },
            'FI': {
                sum: '€700',
                url: 'https://www.victoriamilancasino.com/',
                local: 'fi'
            },
            'DK': {
                sum: '10.000 kr.',
                url: 'https://www.victoriamilancasino.dk/',
                locale: 'da'
            }
        };

        return {
            getCountry: getCountry
        };

        //////////////

        function getCountry() {
            return DomainSettings.get().then(function (domainSettings) {
                var domainCountry = domainSettings.domainCode.split('_')[1];

                var country = countries[domainCountry];

                if (!country) {
                    country = countries[domainSettings.ipCountryCode];
                }

                return country;
            });
        }
    }
})();
'use strict';

(function () {
    'use strict';

    angular.module('vm').filter('translateEnum', translateEnum);

    function translateEnum($translate, CONFIG) {
        return function (input, fieldName) {
            if (fieldName === 'children_number' && Number.isInteger(input)) {
                return input;
            }

            if (fieldName == 'seeking_fors') {
                input = input.split('_')[1];
            }

            if (fieldName == 'status') {
                fieldName = 'relationship_status';
            }

            var fieldConfig = findFieldConfig(fieldName);

            if (fieldConfig && fieldConfig.enumTranslationKey) {
                fieldName = fieldConfig.enumTranslationKey;
            } else {
                fieldName = 'enum' + fieldName;
            }

            return $translate.instant(fieldName + '_' + input);
        };

        function findFieldConfig(fieldName) {
            var fieldConfig = void 0;

            CONFIG.profileSections.forEach(function (section) {
                var fields = section.fields;

                fields.forEach(function (field) {
                    if (field.name == fieldName) {
                        fieldConfig = field;
                    }
                });
            });

            return fieldConfig;
        }
    }
})();
'use strict';

(function () {
    'use strict';

    angular.module('vm').factory('ProfileEnumsService', ProfileEnumsService);

    function ProfileEnumsService($api, CONFIG, translateEnumFilter, $q) {
        var allEnumsPromise = void 0;

        return {
            getAllEnums: getAllEnums,
            getEnums: getEnums,
            getWeights: getWeights,
            getHeights: getHeights
        };

        //////////////

        function getAllEnums() {
            var _this = this;

            if (!allEnumsPromise) {
                allEnumsPromise = $q(function (resolve) {
                    var allEnums = {};

                    allEnums.height = _this.getHeights();
                    allEnums.weight = _this.getWeights();

                    getEnums().then(function (enums) {
                        Object.keys(enums).forEach(function (key) {
                            allEnums[key] = enums[key];
                        });

                        resolve(allEnums);
                    });
                });
            }

            return allEnumsPromise;
        }

        function getEnums() {
            var withPreferNotToSay = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;

            return $api.get('enums/profile?project=' + CONFIG.project).then(function (response) {
                return response.data;
            }).then(function (enums) {
                var convertedEnums = {};

                Object.keys(enums).forEach(function (key) {
                    convertedEnums[key] = enums[key].map(function (option) {
                        return {
                            id: option,
                            text: translateEnumFilter(option, key)
                        };
                    });

                    if (!withPreferNotToSay) {
                        convertedEnums[key] = convertedEnums[key].filter(function (item) {
                            return item.id !== 'preferNotToSay';
                        });
                    }
                });

                convertedEnums['status'] = convertedEnums['relationship_status'];

                return convertedEnums;
            });
        }

        function getWeights() {
            var weights = [];
            var i = void 0;

            for (i = 30; i <= 200; i++) {
                weights.push({
                    id: '' + i,
                    text: i + ' kg'
                });
            }

            return weights;
        }

        function getHeights() {
            var heights = [];
            var i = void 0;

            for (i = 120; i <= 220; i++) {
                heights.push({
                    id: '' + i,
                    text: i + ' cm'
                });
            }

            return heights;
        }
    }
})();
'use strict';

angular.module('vm').component('signUpMay19', {
    templateUrl: 'sign-up-may-19.component.html',
    bindings: {},
    controllerAs: 'vm',
    controller: function controller(signUpMay19Config, $scope, $location, GoogleAnalyticsService, $timeout, SignUpMay19Service) {
        var vm = this;

        vm.steps = signUpMay19Config.steps;
        vm.step = null;
        vm.progress = 0;
        vm.direction = 'forward';
        vm.hasForwardButton = false;

        vm.goBack = goBack;
        vm.goForward = goForward;

        vm.$onInit = activate;

        /////////

        function activate() {
            $scope.$on('$locationChangeSuccess', function () {
                onLocationChange();
            });

            onLocationChange();

            SignUpMay19Service.addPageLeaveListener();
        }

        function onLocationChange() {
            var stepType = $location.search().step;

            var prevStep = vm.step;
            var nextStep = vm.steps.find(function (step) {
                return step.type === stepType;
            }) || vm.steps[0];

            if (vm.steps.indexOf(prevStep) > vm.steps.indexOf(nextStep)) {
                vm.direction = 'back';
            } else {
                vm.direction = 'forward';
            }

            $timeout(function () {
                vm.step = nextStep;

                GoogleAnalyticsService.firePageView();

                updateProgress();

                updateHasForward(vm.step);
            }, 100);
        }

        function updateProgress() {
            var stepIndex = vm.steps.indexOf(vm.step);

            vm.progress = Math.round(stepIndex / vm.steps.length * 100);
        }

        function goBack() {
            history.back();
        }

        function goForward() {
            history.forward();
        }

        function updateHasForward(currentStep) {
            vm.hasForwardButton = false;

            var answer = SignUpMay19Service.getStepAnswer(currentStep.type);

            if (answer) {
                vm.hasForwardButton = true;
            }
        }
    }
});
'use strict';

angular.module('vm').component('signUpMay19AcceptTerms', {
    templateUrl: 'sign-up-may-19-accept-terms.component.html',
    bindings: {},
    controllerAs: 'vm',
    controller: function controller(SignUpMay19Service, SignupFetchErrorMessage, Notifications, Auth, GoogleAnalyticsService) {
        var vm = this;

        vm.formData = {
            agreeWithTerms: false
        };

        vm.submitForm = submitForm;
        vm.$onInit = activate;
        vm.inProcess = false;
        vm.stepType = 'acceptTerms';

        ////////////

        function activate() {
            vm.formData = SignUpMay19Service.getStepAnswer(vm.stepType);
        }

        function submitForm() {
            SignUpMay19Service.saveStepAnswer(vm.stepType, vm.formData);

            if (!vm.inProcess) {
                vm.inProcess = true;

                SignUpMay19Service.sendSignUpForm().then(function (response) {
                    GoogleAnalyticsService.fireEvent('SignUp May 19', 'accept-terms');

                    SignUpMay19Service.removeAnswers();
                    SignUpMay19Service.removePageLeaveListener();
                    Auth.redirectToFrontend(response.data.token);
                }).catch(function (response) {
                    var message = SignupFetchErrorMessage(response.data);

                    if (message) {
                        Notifications.add({
                            html: message
                        });
                    }
                }).finally(function () {
                    vm.inProcess = false;
                });
            }
        }
    }
});
'use strict';

angular.module('vm').component('signUpMay19Birthday', {
    templateUrl: 'sign-up-may-19-birthday.component.html',
    bindings: {},
    controllerAs: 'vm',
    controller: function controller(SignUpMay19Service, GoogleAnalyticsService, $scope) {
        var vm = this;

        vm.birthday = 'dd.mm.yyyy';
        vm.valid = false;
        vm.ageError = false;

        vm.$onInit = activate;
        vm.submitForm = submitForm;
        vm.validateBirthdate = validateBirthdate;
        vm.stepType = 'birthday';

        ////////////

        function activate() {
            addMask();

            var answer = SignUpMay19Service.getStepAnswer(vm.stepType);

            if (answer) {
                vm.birthday = answer;
                validateBirthdate();
            }

            $scope.$watch('vm.birthday', validateBirthdate);
        }

        function submitForm() {
            GoogleAnalyticsService.fireEvent('SignUp May 19', 'birthday');

            SignUpMay19Service.saveStepAnswer(vm.stepType, vm.birthday);
            SignUpMay19Service.goToNextStep(vm.stepType);
        }

        function addMask() {
            var birthdayControl = document.querySelector('.birthday-control');
            IMask(birthdayControl, {
                mask: Date,
                // pattern: 'd{-}`m{-}`Y',
                blocks: {
                    d: { mask: IMask.MaskedRange, placeholderChar: 'd', from: 1, to: 31, maxLength: 2 },
                    m: { mask: IMask.MaskedRange, placeholderChar: 'm', from: 1, to: 12, maxLength: 2 },
                    Y: { mask: IMask.MaskedRange, placeholderChar: 'y', from: 1900, to: 2020, maxLength: 4 }
                },
                lazy: false
                // overwrite: true,
                // autofix: true,
            });
        }

        function validateBirthdate() {
            vm.ageError = false;
            vm.valid = moment(vm.birthday, 'DD.MM.YYYY', true).isValid();
            var years = moment().diff(moment(vm.birthday, 'DD.MM.YYYY'), 'years');

            if (years < 18) {
                vm.valid = false;
                vm.ageError = true;
            }
        }
    }
});
'use strict';

angular.module('vm').component('signUpMay19Email', {
    templateUrl: 'sign-up-may-19-email.component.html',
    bindings: {},
    controllerAs: 'vm',
    controller: function controller($api, $q, $translate, SignUpMay19Service, GoogleAnalyticsService, Notifications) {
        var vm = this;

        vm.formData = {
            email: '',
            agreeWithEmailMarketing: false
        };

        vm.submitForm = submitForm;
        vm.$onInit = activate;
        vm.stepType = 'email';

        /////////////

        function activate() {
            vm.formData = SignUpMay19Service.getStepAnswer(vm.stepType);
        }

        function submitForm() {
            if (!vm.formData.agreeWithEmailMarketing) {
                if (confirm($translate.instant('signup.marketing_email_confirmation'))) {
                    vm.formData.agreeWithEmailMarketing = true;
                }
            }

            checkEmail().then(function () {
                GoogleAnalyticsService.fireEvent('SignUp May 19', 'email');

                SignUpMay19Service.saveStepAnswer(vm.stepType, vm.formData);
                SignUpMay19Service.goToNextStep(vm.stepType);
            }).catch(function (error) {
                Notifications.add({
                    text: error,
                    type: 'error'
                });
            });
        }

        function checkEmail() {
            return $q(function (resolve, reject) {
                $api.get('account/check-available-email?email=' + encodeURIComponent(vm.formData.email)).then(function (response) {
                    if (response.data.notUnique) {
                        reject(response.data.notUnique);
                    }

                    if (response.data.notDeliverable) {
                        reject(response.data.notDeliverable);
                    }

                    if (response.data.validationError) {
                        reject(response.data.validationError);
                    }

                    resolve();
                });
            });
        }
    }
});
'use strict';

angular.module('vm').component('signUpMay19Gender', {
    templateUrl: 'sign-up-may-19-gender.component.html',
    bindings: {},
    controllerAs: 'vm',
    controller: function controller(SignUpMay19Service, GoogleAnalyticsService) {
        var vm = this;

        vm.formData = {};

        vm.options = {
            'sex': [{
                id: 'male',
                label: 'enumusersex_male'
            }, {
                id: 'female',
                label: 'enumusersex_female'
            }],
            'seekingSex': [{
                id: 'male',
                label: 'interested_in.male'
            }, {
                id: 'female',
                label: 'interested_in.female'
            }, {
                id: 'any',
                label: 'interested_in.any'
            }]
        };

        vm.submitForm = submitForm;
        vm.$onInit = activate;
        vm.stepType = 'gender';

        ///////////////

        function activate() {
            vm.formData = SignUpMay19Service.getStepAnswer(vm.stepType);

            if (!vm.formData) {
                vm.formData = {
                    sex: 'male',
                    seekingSex: 'female'
                };
            }
        }

        function submitForm() {
            GoogleAnalyticsService.fireEvent('SignUp May 19', 'sex', vm.formData.sex);

            GoogleAnalyticsService.fireEvent('SignUp May 19', 'seekingSex', vm.formData.seekingSex);

            SignUpMay19Service.saveStepAnswer(vm.stepType, vm.formData);
            SignUpMay19Service.goToNextStep(vm.stepType);
        }
    }
});
'use strict';

angular.module('vm').component('signUpMay19Location', {
    templateUrl: 'sign-up-may-19-location.component.html',
    bindings: {},
    controllerAs: 'vm',
    controller: function controller($api, SignUpMay19Service, GoogleAnalyticsService) {
        var vm = this;

        vm.cities = [];
        vm.selectedCity = null;
        vm.stepType = 'location';

        vm.$onInit = activate;
        vm.searchCity = searchCity;
        vm.submitForm = submitForm;

        ////////

        function activate() {
            vm.selectedCity = SignUpMay19Service.getStepAnswer(vm.stepType);

            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(function (position) {
                    var coords = {
                        latitude: position.coords.latitude,
                        longitude: position.coords.longitude
                    };

                    getCurrentCity(coords);
                }, function () {
                    getCurrentCity();
                });
            } else {
                getCurrentCity();
            }
        }

        function searchCity(q) {
            $api.get('geo/search/city.json?q=' + q).then(function (response) {
                vm.cities = response.data;
            });
        }

        function submitForm() {
            GoogleAnalyticsService.fireEvent('SignUp May 19', 'location');

            SignUpMay19Service.saveStepAnswer(vm.stepType, vm.selectedCity);
            SignUpMay19Service.goToNextStep(vm.stepType);
        }

        function getCurrentCity(coords) {
            var url = 'geo/detect/city';

            if (coords) {
                url += '?latitude=' + coords.latitude + '&longitude=' + coords.longitude;
            }

            $api.get(url).then(function (response) {
                if (+response.data.id > 0) {
                    vm.selectedCity = response.data;
                }
            });
        }
    }
});
'use strict';

angular.module('vm').component('signUpMay19Organic', {
    templateUrl: 'sign-up-may-19-organic.component.html',
    bindings: {},
    controllerAs: 'vm',
    controller: function controller(SignUpMay19Service, CONFIG, GoogleAnalyticsService) {
        var vm = this;

        vm.stepType = 'organic';
        vm.CONFIG = CONFIG;
        vm.brandName = CONFIG.projectName.match(/[A-Z][a-z]+/g).join(' ');
        vm.answer = null;

        vm.sendResponse = sendResponse;
        vm.$onInit = activate;

        ///////////

        function activate() {
            vm.answer = SignUpMay19Service.getStepAnswer(vm.stepType);
        }

        function sendResponse(answer) {
            GoogleAnalyticsService.fireEvent('SignUp May 19', 'organic', answer);

            SignUpMay19Service.saveStepAnswer(vm.stepType, answer);
            SignUpMay19Service.goToNextStep(vm.stepType);
        }
    }
});
'use strict';

angular.module('vm').component('signUpMay19Password', {
    templateUrl: 'sign-up-may-19-password.component.html',
    bindings: {},
    controllerAs: 'vm',
    controller: function controller(SignUpMay19Service, GoogleAnalyticsService) {
        var vm = this;

        vm.valid = false;
        vm.formData = {
            password: ''
        };

        vm.passwordRules = {
            latter: {
                valid: false,
                rule: /([a-zA-Z])/
            },
            number: {
                valid: false,
                rule: /([0-9])/
            },
            length: {
                valid: false,
                rule: /(.{6,})/
            }
        };

        vm.passwordVisible = false;
        vm.stepType = 'password';

        vm.checkRules = checkRules;
        vm.submitForm = submitForm;
        vm.$onInit = activate;

        ////////////

        function activate() {
            var answer = SignUpMay19Service.getStepAnswer(vm.stepType);

            if (answer) {
                vm.formData = answer;
                checkRules();
            }
        }

        function submitForm() {
            GoogleAnalyticsService.fireEvent('SignUp May 19', 'password');

            SignUpMay19Service.saveStepAnswer(vm.stepType, vm.formData);
            SignUpMay19Service.goToNextStep(vm.stepType);
        }

        function checkRules() {
            vm.valid = true;

            Object.keys(vm.passwordRules).forEach(function (key) {
                if (vm.passwordRules[key].rule.test(vm.formData.password)) {
                    vm.passwordRules[key].valid = true;
                } else {
                    vm.passwordRules[key].valid = false;
                    vm.valid = false;
                }
            });
        }
    }
});
'use strict';

angular.module('vm').component('signUpMay19Security', {
    templateUrl: 'sign-up-may-19-security.component.html',
    bindings: {},
    controllerAs: 'vm',
    controller: function controller() {
        var vm = this;
    }
});
'use strict';

angular.module('vm').component('signUpMay19UserDetails', {
    templateUrl: 'sign-up-may-19-user-details.component.html',
    bindings: {
        step: '='
    },
    controllerAs: 'vm',
    controller: function controller(ProfileEnumsService, SignUpMay19Service, CONFIG, $scope, $timeout, GoogleAnalyticsService, $element) {
        var vm = this;

        var defaultValues = {
            'female': {
                'height': 150,
                'weight': 50
            },
            'male': {
                'height': 170,
                'weight': 70
            }
        };

        vm.enums = {};
        vm.fieldConfig;
        vm.answer;

        vm.addAnswer = addAnswer;
        vm.findFieldConfig = findFieldConfig;

        vm.$onInit = activate;

        ///////////

        function activate() {
            onStepUpdate();

            $scope.$watch('vm.step', function () {
                onStepUpdate();
            });
        }

        function addAnswer(option) {
            vm.answer = option.id;

            GoogleAnalyticsService.fireEvent('SignUp May 19', vm.step.type, option.id);

            SignUpMay19Service.saveStepAnswer(vm.step.type, option.id);

            $timeout(function () {
                return SignUpMay19Service.goToNextStep(vm.step.type);
            }, 0);
        }

        function updateFieldConfig() {
            vm.fieldConfig = findFieldConfig(vm.step.type);
        }

        function findFieldConfig(fieldName) {
            var fieldConfig = void 0;

            CONFIG.profileSections.forEach(function (section) {
                var fields = section.fields;

                fields.forEach(function (field) {
                    if (field.name == fieldName) {
                        fieldConfig = field;
                    }
                });
            });

            return fieldConfig;
        }

        function onStepUpdate() {
            updateFieldConfig();
            vm.answer = SignUpMay19Service.getStepAnswer(vm.step.type);

            ProfileEnumsService.getAllEnums().then(function (enums) {
                vm.enums = enums;

                if (vm.step.type === 'height' || vm.step.type === 'weight') {
                    scrollToOption(vm.answer);
                }
            });
        }

        function scrollToOption(answer) {
            $timeout(function () {
                var value = void 0;

                if (answer) {
                    value = answer;
                } else {
                    var genderAnswer = SignUpMay19Service.getStepAnswer('gender');

                    if (genderAnswer) {
                        var defaultValue = defaultValues[genderAnswer.sex][vm.step.type];

                        if (defaultValue) {
                            value = defaultValue;
                        }
                    }
                }

                if (value) {
                    var el = $element[0].querySelector('#answer-' + value);
                    var containerEl = $element[0].querySelector('.step-content');

                    if (el) {
                        containerEl.scroll({
                            top: el.offsetTop - 300,
                            left: 0,
                            behavior: 'smooth'
                        });
                    }
                }
            }, 100);
        }
    }
});
'use strict';

angular.module('vm').component('signUpMay19Username', {
    templateUrl: 'sign-up-may-19-username.component.html',
    bindings: {},
    controllerAs: 'vm',
    controller: function controller(CONFIG, SignUpMay19Service, $q, $api, GoogleAnalyticsService, Notifications) {
        var vm = this;

        vm.CONFIG = CONFIG;
        vm.formData = {
            username: ''
        };

        vm.stepType = 'username';

        vm.submitForm = submitForm;
        vm.$onInit = activate;

        ////////////

        function activate() {
            vm.formData = SignUpMay19Service.getStepAnswer(vm.stepType);
        }

        function submitForm() {
            checkUsername().then(function () {
                GoogleAnalyticsService.fireEvent('SignUp May 19', 'username');

                SignUpMay19Service.saveStepAnswer(vm.stepType, vm.formData);
                SignUpMay19Service.goToNextStep(vm.stepType);
            }).catch(function (error) {
                Notifications.add({
                    text: error,
                    type: 'error'
                });
            });
        }

        function checkUsername() {
            return $q(function (resolve, reject) {
                $api.get('account/check-available-name?name=' + encodeURIComponent(vm.formData.username)).then(function (response) {
                    if (response.data.success) {
                        resolve();
                    } else {
                        reject(response.data.error);
                    }
                });
            });
        }
    }
});
'use strict';

(function () {
    'use strict';

    angular.module('vm').factory('SignUpMay19Service', SignUpMay19Service);

    function SignUpMay19Service(localStorageService, signUpMay19Config, $location, $api) {
        var localStorageKey = 'sign-up-may-19-step-answers';

        return {
            saveStepAnswer: saveStepAnswer,
            getStepAnswer: getStepAnswer,
            goToNextStep: goToNextStep,
            sendSignUpForm: sendSignUpForm,
            removeAnswers: removeAnswers,
            getSignUpFormData: getSignUpFormData,
            addPageLeaveListener: addPageLeaveListener,
            removePageLeaveListener: removePageLeaveListener
        };

        //////////////

        function saveStepAnswer(stepType, answer) {
            var stepAnswers = getStepAnswers();

            stepAnswers[stepType] = answer;

            localStorageService.set(localStorageKey, stepAnswers);
        }

        function goToNextStep(currentStep) {
            var steps = signUpMay19Config.steps;

            var _iteratorNormalCompletion = true;
            var _didIteratorError = false;
            var _iteratorError = undefined;

            try {
                for (var _iterator = steps[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
                    var step = _step.value;

                    if (step.type === currentStep) {
                        var nextStep = steps[steps.indexOf(step) + 1];

                        if (nextStep) {
                            $location.search('step', nextStep.type);
                            break;
                        }
                    }
                }
            } catch (err) {
                _didIteratorError = true;
                _iteratorError = err;
            } finally {
                try {
                    if (!_iteratorNormalCompletion && _iterator.return) {
                        _iterator.return();
                    }
                } finally {
                    if (_didIteratorError) {
                        throw _iteratorError;
                    }
                }
            }
        }

        function sendSignUpForm() {
            var formData = getSignUpFormData();

            var data = {
                api_signup_by_group: formData
            };

            return $api.post('account/signup-by-group', data);
        }

        function getSignUpFormData() {
            var formData = {};

            var answers = getStepAnswers();

            formData.group = 'new_signup_may19';
            formData.name = answers.username.username;
            formData.email = answers.email.email;
            formData.sex = answers.gender.sex;
            formData.seekingSex = answers.gender.seekingSex;
            formData.relationshipStatus = answers.relationship_status;
            formData.password = answers.password.password;
            formData.birthDate = moment(answers.birthday, 'DD.MM.YYYY').format('YYYY-MM-DD');
            formData.agreeWithTerms = answers.acceptTerms.agreeWithTerms;
            formData.agreeWithEmailMarketing = answers.email.agreeWithEmailMarketing;
            formData.height = answers.height;
            formData.weight = answers.weight;
            formData.eyes = answers.eyes;
            formData.hair = answers.hair;
            formData.bodyType = answers.body_type;
            formData.looks = answers.looks;
            formData.registerCampaignId = answers.organic;
            formData.city_id = answers.location.id;

            return formData;
        }

        function getStepAnswer(stepType) {
            var stepAnswers = getStepAnswers();

            return stepAnswers[stepType];
        }

        function getStepAnswers() {
            var stepAnswers = localStorageService.get(localStorageKey);

            if (!stepAnswers) {
                stepAnswers = {};
            }

            return stepAnswers;
        }

        function removeAnswers() {
            localStorageService.remove(localStorageKey);
        }

        function addPageLeaveListener() {
            window.addEventListener('beforeunload', onPageLeave);
        }

        function removePageLeaveListener() {
            window.removeEventListener('beforeunload', onPageLeave);
        }

        function onPageLeave(event) {
            event.preventDefault();
            event.returnValue = '';
        }
    }
})();
'use strict';

angular.module('vm').constant('signUpMay19Config', {
    steps: [{
        type: 'gender',
        component: 'gender'
    }, {
        type: 'location',
        component: 'location'
    }, {
        type: 'relationship_status',
        component: 'user-details'
    }, {
        type: 'height',
        component: 'user-details'
    }, {
        type: 'weight',
        component: 'user-details'
    }, {
        type: 'looks',
        component: 'user-details'
    }, {
        type: 'body_type',
        component: 'user-details'
    }, {
        type: 'eyes',
        component: 'user-details'
    }, {
        type: 'hair',
        component: 'user-details'
    }, {
        type: 'birthday',
        component: 'birthday'
    }, {
        type: 'organic',
        component: 'organic'
    }, {
        type: 'email',
        component: 'email'
    }, {
        type: 'password',
        component: 'password'
    }, {
        type: 'username',
        component: 'username'
    }, {
        type: 'acceptTerms',
        component: 'accept-terms'
    }]
});
'use strict';

(function () {
    'use strict';

    angular.module('vm').factory('GoogleAnalyticsService', GoogleAnalyticsService);

    function GoogleAnalyticsService() {

        return {
            fireEvent: fireEvent,
            firePageView: firePageView
        };

        //////////////

        function fireEvent(category, action, label) {
            var event = {
                hitType: 'event',
                eventCategory: category,
                eventAction: action,
                eventLabel: label
            };

            console.log('Send GA event', event);

            if ('ga' in window) {
                ga('send', event);
            }
        }

        function firePageView(page) {
            if (!page) {
                page = window.location.hash;
            }

            var pageView = { 'page': page };

            console.log('Send GA pageview', pageView);

            if ('ga' in window) {
                ga('send', 'pageview', pageView);
            }
        }
    }
})();