angular
    .module('vm')
    .constant('signUpMay19Config', {
        steps: [
            {
                type: 'gender',
                component: 'gender',
            },
            {
                type: 'location',
                component: 'location',
            },
            {
                type: 'relationship_status',
                component: 'user-details',
            },
            {
                type: 'height',
                component: 'user-details',
            },
            {
                type: 'weight',
                component: 'user-details',
            },
            {
                type: 'looks',
                component: 'user-details',
            },
            {
                type: 'body_type',
                component: 'user-details',
            },
            {
                type: 'eyes',
                component: 'user-details',
            },
            {
                type: 'hair',
                component: 'user-details',
            },
            {
                type: 'birthday',
                component: 'birthday',
            },
            {
                type: 'organic',
                component: 'organic',
            },
            {
                type: 'email',
                component: 'email',
            },
            {
                type: 'password',
                component: 'password',
            },
            {
                type: 'username',
                component: 'username',
            },
            {
                type: 'acceptTerms',
                component: 'accept-terms',
            },
        ],
    })
;