(function () {
    'use strict';

    angular
        .module('vm')
        .factory('GoogleAnalyticsService', GoogleAnalyticsService);

    function GoogleAnalyticsService () {

        return {
            fireEvent: fireEvent,
            firePageView: firePageView,
        };

        //////////////

        function fireEvent(category, action, label) {
            var event = {
                hitType: 'event',
                eventCategory: category,
                eventAction: action,
                eventLabel: label
            };

            console.log('Send GA event', event);

            if ('ga' in window) {
                ga('send', event);
            }
        }

        function firePageView(page) {
            if (!page) {
                page = window.location.hash;
            }

            var pageView = {'page': page};

            console.log('Send GA pageview', pageView);

            if ('ga' in window) {
                ga('send', 'pageview', pageView);
            }
        }
    }
}());