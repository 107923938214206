angular
    .module('vm')
    .component('signUpMay19Username', {
        templateUrl: 'sign-up-may-19-username.component.html',
        bindings: {

        },
        controllerAs: 'vm',
        controller: function (CONFIG, SignUpMay19Service, $q, $api, GoogleAnalyticsService, Notifications) {
            const vm = this;

            vm.CONFIG = CONFIG;
            vm.formData = {
                username: '',
            };

            vm.stepType = 'username';

            vm.submitForm = submitForm;
            vm.$onInit = activate;

            ////////////

            function activate() {
                vm.formData = SignUpMay19Service.getStepAnswer(vm.stepType);
            }

            function submitForm() {
                checkUsername()
                    .then(() => {
                        GoogleAnalyticsService.fireEvent(
                            'SignUp May 19', 
                            'username'
                        );
                        
                        SignUpMay19Service.saveStepAnswer(vm.stepType, vm.formData);
                        SignUpMay19Service.goToNextStep(vm.stepType);
                    })
                    .catch((error) => {
                        Notifications.add({
                            text: error,
                            type: 'error'
                        });
                    })
                ;
            }

            function checkUsername() {
                return $q((resolve, reject) => {
                    $api.get('account/check-available-name?name=' + encodeURIComponent(vm.formData.username))
                        .then((response) => {
                            if (response.data.success) {
                                resolve();
                            } else {
                                reject(response.data.error);
                            }
                        })
                    ;
                });
            }
        }
    })
;