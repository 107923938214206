"use strict";

var LandingAbTest = {
    data: {},

    initialize: function () {
        var self = this;
        window.addEventListener('digisec:on-tracking-executed', function () {
            self.abTestInit();
        });
    },

    abTestInit: function() {
        var self = this,
            http = new XMLHttpRequest();
        http.open("POST", '/api/v4.0/settings/lander-ab-test', true);
        http.setRequestHeader("Content-type", "application/json;charset=UTF-8");
        http.onreadystatechange = function() {
            if (http.readyState === 4) {
                var response = JSON.parse(http.response);
                console.log(response);

                if (response['js']) {
                    eval(response['js']);
                }
            }
        };
        http.send();
    }
};

LandingAbTest.initialize();