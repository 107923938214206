angular
    .module('vm')
    .component('signUpMay19Location', {
        templateUrl: 'sign-up-may-19-location.component.html',
        bindings: {

        },
        controllerAs: 'vm',
        controller: function ($api, SignUpMay19Service, GoogleAnalyticsService) {
            const vm = this;

            vm.cities = [];
            vm.selectedCity = null;
            vm.stepType = 'location';

            vm.$onInit = activate;
            vm.searchCity = searchCity;
            vm.submitForm = submitForm;

            ////////

            function activate() {
                vm.selectedCity = SignUpMay19Service.getStepAnswer(vm.stepType);

                if (navigator.geolocation) {
                    navigator.geolocation.getCurrentPosition(
                        (position) => {
                            const coords = {
                                latitude: position.coords.latitude,
                                longitude: position.coords.longitude,
                            };

                            getCurrentCity(coords);
                        },
                        () => {
                            getCurrentCity();
                        }
                    );
                } else {
                    getCurrentCity();
                }
            }

            function searchCity(q) {
                $api.get(`geo/search/city.json?q=${q}`)
                    .then((response) => {
                        vm.cities = response.data;
                    })
                ;
            }

            function submitForm() {
                GoogleAnalyticsService.fireEvent(
                    'SignUp May 19', 
                    'location'
                );

                SignUpMay19Service.saveStepAnswer(vm.stepType, vm.selectedCity);
                SignUpMay19Service.goToNextStep(vm.stepType);
            }

            function getCurrentCity(coords) {
                let url = 'geo/detect/city';

                if (coords) {
                    url += '?latitude=' + coords.latitude + '&longitude=' + coords.longitude;
                }

                $api.get(url)
                    .then((response) => {
                        if (+response.data.id > 0) {
                            vm.selectedCity = response.data;
                        }
                    })
                ;
            }
        }
    })
;